import React, { useState, useEffect } from 'react'
import { FormGroup,Form, FormControl, Button,Dropdown,DropdownButton, Spinner } from 'react-bootstrap';
import database from '../../../service/firebaseConfig';
import UpdatedDate from '../../../CommonRequirements/UpdatedDate';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ALL_REGIONS_DD } from '../../../constants/allConstants';
import axios from 'axios';
import {ORDERING_BASE_URL} from '../../../service/servicesConfig';

const salesmanDetails = database.database().ref('SalesMan_Details');
const retailerDetails = database.database().ref('Retailer_Master_New');

function AddSalesman() {


    useEffect(() => {
        const hashWithCharactersRegex = /^#.*/;
        const finalHashWithCharactersRegex = /^\?#.*/;
        const baseURL = window.location.origin;
        if (window.location.hash.search(hashWithCharactersRegex) !== -1 && window.location.hash.search(finalHashWithCharactersRegex) === -1) {
            const url = `${baseURL}/?#/custom_portal_index_update`;
     
            window.location.replace(url)
        }
    }, []);


    const [maxId, setMaxId] = useState("")
    const [ASMName, setASMName] = useState("")
    const [ZSMName, setZSMName] = useState("")
    const [DeputyName, setDeputyName] = useState("")
    const [ASMId, setASMId] = useState("")
    const [ZSMId, setZSMId] = useState("")
    const [DeputyId, setDeputyId] = useState("")
    const [password, setPassword] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [salesmanName, setSalesmanName] = useState("")
    const [salesmanType, setSalesmanType] = useState("")
    const [storeSeries, setStoreSeries] = useState("")
    const [details, setDetails] = useState(false)
    const [SeList, setSeList] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [AsmList,  setAsmList] = useState([])
    const [ZsmList,  setZsmList] = useState([])
    const [DasmList, setDasmList] = useState([])
    const [tradeType, setTradeType] = useState("");



    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    const notifyUser = (success) => {
        if (success) {
            return toast("Successfully added the salesman", { type: toast.TYPE.SUCCESS });
        }
        return toast("Couldn't add salesman", { type: toast.TYPE.ERROR });
    };

    function formatDateToCustomString() {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const currentTime = new Date();
        const currentOffset = currentTime.getTimezoneOffset();
        const ISTOffset = 330;   // IST offset UTC +5:30 
        const date = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
        const year = date.getUTCFullYear();
        const month = months[date.getUTCMonth()];
        const day = days[date.getUTCDay()];
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const timezoneOffset = date.getTimezoneOffset();
        const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60);
        const timezoneMinutes = Math.abs(timezoneOffset) % 60;
        const timezonePrefix = timezoneOffset >= 0 ? '+' : '-';
        const formattedString = `${day} ${month} ${date.getUTCDate()} ${hours}:${minutes}:${seconds} GMT${timezonePrefix}${String(timezoneHours).padStart(2, '0')}:${String(timezoneMinutes).padStart(2, '0')} ${year}`;
        return formattedString;
      }
      

    const pushSalesmanData = async () => {


        const phoneRegex = /^\d{10}$/; 
        if(!phoneRegex.test(phoneNo)){
            toast("PHONE NUMBER is not in correct format", { type: toast.TYPE.ERROR })
            return 
        }

        if(salesmanName == null || salesmanName == "")
        {
            toast("Please Provide Salesman Name", { type: toast.TYPE.ERROR })
            return
        }

        if(salesmanType == null || salesmanType == ""){
            toast("Select Salesman Type", { type: toast.TYPE.ERROR })
            return
        }

        if(password == null || password == ""){
            toast("Password field cannot be empty", { type: toast.TYPE.ERROR })
            return
        }
        if(tradeType == null || tradeType == ""){
            toast("Trade type field cannot be empty", { type: toast.TYPE.ERROR })
            return
        }

        setIsButtonDisabled(true)

        try {
            const sales = await salesmanDetails.child(phoneNo).once("value");
            const sales_val = sales.val();
            const retailer = await retailerDetails.child(phoneNo).once("value");
            const retailer_val = retailer.val();

            if (sales_val !== null && retailer_val !== null) {
                toast("Couldn't add salesman, phoneNo already present", { type: toast.TYPE.ERROR });
                setIsButtonDisabled(false)
                return;
            } else if (retailer_val == null && sales_val !== null) {
                const retailer_id = sales_val['salesman_id']
                retailerDetails.child(phoneNo).set({
                    address1: "SuperZop",
                    address2: "SuperZop",
                    phone: phoneNo,
                    shop_name: "SuperZop",
                    city: "Mumbai",
                    state: "Maharashtra",
                    user_name: phoneNo,
                    registration_date: formatDateToCustomString(),
                    store_series: storeSeries,
                    password,
                    verified: 'Y',
                    retailer_id: Number(retailer_id)
                    })
                toast("Salesman already present, added retailer record", { type: toast.TYPE.ERROR });
                setIsButtonDisabled(false)
                return;
            } else if(retailer_val != null) {
                toast("Retailer record already present", { type: toast.TYPE.ERROR });
                setIsButtonDisabled(false)
                return;
            }
        } catch (error) {
            console.error("Error occurred:", error);
            toast("Couldn't add salesman", { type: toast.TYPE.ERROR });
            setIsButtonDisabled(false)
            return
        }

        let insert_obj = {
                asm_id: ASMId,
                asm: ASMName,
                zsm_id: ZSMId,
                zsm: ZSMName,
                deputy_asm_id: DeputyId?DeputyId:"",
                deputy_asm: DeputyName?DeputyName:"",
                password,
                phone_number: phoneNo,
                salesman_name: salesmanName,
                salesman_type: salesmanType,
                trade_type:tradeType,
                store_series: storeSeries,
                salesman_id:maxId.toString(),
                status: "1",
                last_updated: UpdatedDate()
            }
           
            
        salesmanDetails.child(phoneNo).update(insert_obj).then(()=>{
                
                notifyUser(true);
                retailerDetails.child(phoneNo).set({
                    address1: "SuperZop",
                    address2: "SuperZop",
                    phone: phoneNo,
                    shop_name: "SuperZop",
                    city: "Mumbai",
                    state: "Maharashtra",
                    user_name: phoneNo,
                    registration_date: formatDateToCustomString(),
                    store_series: storeSeries,
                    password,
                    verified: 'Y',
                    retailer_id: Number(maxId)
                    })

                
                setTimeout(() => {         
                window.location.reload()
            }, 7000)})




    }

    const fetchSalesmanDetails = () => {

        let maximumId = "";
        salesmanDetails.once("value", salesManInfo => {

            const salesmanData = salesManInfo.val();
            salesManInfo.forEach(salesmanId => {
                salesmanId = salesmanId.val();
                
                if (salesmanId.salesman_id > maximumId)
                    maximumId = salesmanId.salesman_id
            })
        }).then(() => {setMaxId(parseInt(maximumId) + 1)})
    }

    const getAsmDsm = () =>{

        const url = `${ORDERING_BASE_URL}/api/superzop/admin/salesman/listofse`

        axios.get(url)
          .then((result)=>{
            
            setSeList(result.data);

            let zsm ={}
            let asm = {}
            let deputy_asm = {}
      
            for (let  itm of result.data){

                if(zsm[itm.zsm_id] === undefined ){
                    zsm[itm.zsm_id] = itm.zsm
                }

                if(asm[itm.asm_id] === undefined ){
                    asm[itm.asm_id] = itm.asm
                }

                if(deputy_asm[itm.deputy_asm_id] === undefined ){
                    deputy_asm[itm.deputy_asm_id] = itm.deputy_asm
                }
            }

            setZsmList(zsm)
            setAsmList(asm)
            setDasmList(deputy_asm)


          })
          .catch(function (error) {
            console.log(error);
          });

    } 


    useEffect(() => {

        fetchSalesmanDetails();
        getAsmDsm();
    }, [])

    useEffect(() => {
        if (details)
        {
            pushSalesmanData()
            setDetails(false)
        }
    }, [details])

    useEffect(() => {

    setDeputyName("");
    setDeputyId("");
    setASMName(""); 
    setASMId("");
    
    let asm = {}
    let deputy_asm = {}
    
    for (let se of SeList){
        
        if (se.zsm_id == ZSMId){
            
            if(asm[se.asm_id] === undefined ){
                asm[se.asm_id] = se.asm
            }

            if(deputy_asm[se.deputy_asm_id] === undefined ){
                deputy_asm[se.deputy_asm_id] = se.deputy_asm
            }
        }

    }

    setAsmList(asm)
    setDasmList(deputy_asm)
  
    }, [ZSMId])
    


    return (
        <div style={{ padding: '1em' }}>
            <div className='container'>
                <form onSubmit={salesmanDetails}>
                    <h3 className='text-center'><b>ADD SALESMAN</b></h3>

                    
                    <div className='form-group'>
                        <label htmlFor='salesmanid'> SALESMAN ID</label>
                        <Form.Control className="form-control" type="text" id="salesm" value={maxId ? maxId : "Loading.."} readOnly />
                    </div>
                    
                    

                    <div className='form-group'>
                        <label htmlFor='new_phone_number'>ENTER SALESMAN TYPE</label>
                        <DropdownButton id="dropdown-basic-button" title={salesmanType ? salesmanType : "--Select--"}>

                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="salesexec">salesexec</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="deputyasm">deputyasm</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="asm">asm</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="mgmt">mgmt</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="zsm">zsm</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="support">support</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setSalesmanType(e.target.name) }} name="commercial">commercial</Dropdown.Item>

                        </DropdownButton>
                    </div>



                    <div className='form-group'>
                     { (salesmanType == 'salesexec' || salesmanType == 'deputyasm' || salesmanType == 'asm') && (<div>
                        <label htmlFor='ZSM'>select ZSM</label>
                        <DropdownButton id="dropdown-basic-button" title={ZSMName ? ZSMName : "--Select--"}>
                        <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("") }} name="">All</Dropdown.Item>
                            {
                               ZsmList && Object.keys(ZsmList).map((item)=>{
                                    return( <Dropdown.Item key={item} onClick={(e) => {setZSMName(e.target.name); setZSMId(item) }} name={ZsmList[item]}> {ZsmList[item]} </Dropdown.Item>)
                                })
                            }

                            {/* <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("92272") }} name="Deepak Prasad">Deepak Prasad</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("90078") }} name="Santosh">Santosh</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("90010") }} name="JP">JP</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("90075") }} name="Sagar">Sagar</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setZSMName(e.target.name); setZSMId("90009") }} name="Bikas">Bikas</Dropdown.Item> */}

                        </DropdownButton>
                        </div>)}

                       {(salesmanType == 'salesexec' || salesmanType == 'deputyasm') && (<div>
                        <label htmlFor='ASM'>select ASM</label>
                        <DropdownButton id="dropdown-basic-button" title={ASMName ? ASMName : "--Select--"}>
                        <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("") }} name=""> All</Dropdown.Item>
                            {
                              AsmList && Object.keys(AsmList).map((item)=>{
                                    return(
                                    <Dropdown.Item key={item} onClick={(e) => {setASMName(e.target.name); setASMId(item) }} name={AsmList[item]}> {AsmList[item]} </Dropdown.Item>)
                                })
                            }

                            {/* <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("92272") }} name="Deepak Prasad">Deepak Prasad</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90078") }} name="Santosh">Santosh</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90147") }} name="Pune ASM">Pune ASM</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90010") }} name="JP">JP</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90075") }} name="Sagar">Sagar</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90009") }} name="Bikas">Bikas</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("92122") }} name="Atul Tikekar">Atul Tikekar</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("92249") }} name="Amit Yadav">Amit Yadav</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("92039") }} name="Navi Mumbai ASM">Navi Mumbai ASM</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setASMName(e.target.name); setASMId("90000") }} name="SuperZop">SuperZop</Dropdown.Item> */}

                        </DropdownButton>
                        </div>)}
                      {  (salesmanType == 'salesexec' ) && (<div>
                        <label htmlFor='Deputy ASM'>select Deputy ASM (If Applicable)</label>
                        <DropdownButton id="dropdown-basic-button" title={DeputyName ? DeputyName : "--Select--"}>
                        <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("") }} name=" ">All </Dropdown.Item>
                            {
                                DasmList && Object.keys(DasmList).map((item)=>{
                                    return(
                                    <Dropdown.Item key={item} onClick={(e) => {setDeputyName(e.target.name); setDeputyId(item) }} name={DasmList[item]}> {DasmList[item]} </Dropdown.Item>)
                                })
                            }
                            
                        {/* 
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92025") }} name="Satish Jaiswal">Satish Jaiswal</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92204") }} name="Sachin Sahu">Sachin Sahu</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92040") }} name="Dileep Umakant Shukla">Dileep Umakant Shukla</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92202") }} name="Tanvi Kamble">Tanvi Kamble</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92203") }} name="Rajan Tiwari">Rajan Tiwari	</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92306") }} name="Ajinkya Jadhav">Ajinkya Jadhav</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92205") }} name="Jeevan">Jeevan</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92208") }} name="Sachin Salunke">Sachin Salunke</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { setDeputyName(e.target.name); setDeputyId("92206") }} name="Rizwan">Rizwan</Dropdown.Item> */}

                        </DropdownButton>
                        </div>)}
                    </div>

              


                    <div className='form-group'>
                        <label htmlFor='new_phone_number'> ASM ID</label>
                        <input
                            id='asmid'
                            className='form-control'
                            type='text'
                            value={ASMName ? ASMId : ""}
                            readOnly
                            required

                        />
                    </div>

                    <div className='form-group'>
                     <label htmlFor='trade_type'>SELECT TRADE TYPE <span style={{ color: 'red' }}>*</span></label>
                     <DropdownButton id="dropdown-trade-type" title={tradeType ? tradeType : "--Select--"}>
                     <Dropdown.Item onClick={() => setTradeType("GT")} name="GT">GT</Dropdown.Item>
                     <Dropdown.Item onClick={() => setTradeType("MT")} name="MT/Online">MT/Online</Dropdown.Item>
                     <Dropdown.Item onClick={() => setTradeType("Fresh")} name="Fresh">Fresh</Dropdown.Item>
                     </DropdownButton>
                    </div>



                    <div className='form-group'>
                        <label htmlFor='new_phone_number'>ENTER PASSWORD</label>
                        <input
                            className='form-control'
                            type='text'
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>



                    <div className='form-group'>
                        <label htmlFor='new_phone_number'>ENTER PHONE NUMBER</label>
                        <input
                            className='form-control'
                            type='text'
                            onChange={(e) => setPhoneNo(e.target.value)}
                            required
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='new_phone_number'>ENTER SALESMAN NAME</label>
                        <input
                            className='form-control'
                            type='text'
                            onChange={(e) => setSalesmanName(e.target.value)}
                            required
                        />
                    </div>
      

                    <div className='form-group'>
                        <label htmlFor='new_phone_number'>ENTER STORE SERIES</label>
                        <Select         
                            id="dropdown-basic-button" 
                            title={storeSeries ? storeSeries : "--Select--"}
                            options={
                                ALL_REGIONS_DD
                            }
                            onChange={(item) => { setStoreSeries(item.value) }}
                            classNamePrefix="select"
                        />
                    </div>
                    <div>
                        <button type='submit' onClick={() => setDetails(true)} className="btn btn-info" disabled={isButtonDisabled} >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddSalesman
