import React, { useState, useEffect, useRef } from "react";
import { 
  Paper,
  Modal, 
  Box, 
  Typography, 
  Button,
  Divider,
  Table, 
  TableContainer,
  TableBody, 
  TableRow, 
  TableCell, 
  TextField, 
  Select, 
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Tooltip } 
from '@mui/material';
import { InputGroupAddon } from "reactstrap";
// import DatePicker from "react-datepicker";
// import Select from "react-select";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
// import Creatable from "react-select/creatable";
// import Switch from "react-switch";
// import request from "request";
import baseUrl from "../../service/servicesConfig";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { getUserType, validateIsAdminUser } from "../../service/credentials";
import moment from "moment";



const productsMasterDB = database.database().ref(`Product_Master_New/A`);
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
const SuppliersDB = database.database().ref(`Suppliers`);
const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);

const sessionDate = new Date();
let sessionMonth = sessionDate.getMonth() + 1;
if (sessionMonth < 10) sessionMonth = "0" + sessionMonth;
let sessionDt = sessionDate.getDate();
if (sessionDt < 10) sessionDt = "0" + sessionDt;

function EditGRNModal({
  open, 
  onClose,
  data,
  itemCode,
  selectedDate,
  selectedMonth,
  selectedYear,
  setAllEditItemDetails, // mutating this state here from NewListPurchaseAddAndEdit.js
  setEditSaveClick, // mutating this state in NewListPurchaseAddAndEdit.js
  supplierName,
  setPO_date,
  po_date,
  selectedPO_Item,
  ...props
}) {

  const items_transport_charges = useRef(data.transport_charges || null);

  const [suppliersList, setSuppliersList] = useState([]);

  const [productEditObject, setProductEditObject] = useState({});
  const [materialReceivingDt, setMaterialReceivingDt] = useState(
    data.grn_date && data.grn_date !== ""
      ? new Date(
          data.grn_date.split("-")[0],
          data.grn_date.split("-")[1] - 1,
          data.grn_date.split("-")[2]
        )
      : new Date()
  );
  const [bagPurchased, setBagPurchased] = useState(data.bag_purchased || "");
  const [totalWtPurchased, setTotalWtPurchased] = useState(
    Number(data.total_weight)
  );
  const [invoiceNoList, setInvoiceNoList] = useState("");
  const [updateGrn, setUpdateGrn] = useState(false);
  const [billSubmitConfirm, setBillSubmitConfirm] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [replaceBrand, setReplaceBrand] = useState(data.replacement_brand || "");
  const [purchasePrice, setPurchasePrice] = useState(data.purchase_price || "")
  const [errors, setError] = useState({ // All Mandatory fields Check
    replacement_brand:"",
    no_of_bags:"",
    purchase_price:""
  });
  const [invoiceDate, setInvoiceDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [invoiceRatePer, setInvoiceRatePer] = useState(Number(data.purchase_price));
  const [allUpdatingEditItemsHash, setAllUpdatingEditItemsHash] = useState({})
  // this state being controlled from NewPurchaseViewEdit.js
  // const [po_date, setPO_date] = useState(data.purchased_date || '')
  const diffWtRef = useRef();
  const totalReceivedWt = useRef();
  const bagReceivedRef = useRef(null);
  const supplierRef = useRef();
  
  // console.log("1",diffWtRef.current)
  // console.log("2",totalReceivedWt.current)
  // console.log("3",bagReceivedRef.current)
  // console.log("4",supplierRef.current)

  const [itemsFields, setItemsFields] = useState({});
  const [gstChargeOnItemNameChange,setGstChargeOnItemNameChange] = useState(data.gstPerFromFb )
  const [apmcChargeOnItemNameChange,setApmcChargeOnItemNameChange] = useState(data.apmcPerFromFb)
  
  useEffect(() => {
    if (data) {

      const itemNameResult = priceDetailsDB
      .child(`${data.article_code}`)
      .once('value')
      .then((snapShot) => {
        console.log("data")
        console.log(snapShot.val());
        const selectedItemValue = snapShot.val();
         
        return selectedItemValue;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        return error;
      });
    // Wait for the Promise to resolve before using the gstRate_ value
    itemNameResult.then((selectedItemValue) => {
      const transport_charges = selectedItemValue?.mill_transport_value ? selectedItemValue.mill_transport_value  : "";
      items_transport_charges.current = transport_charges
     });

      
      setItemsFields((prevState) => ({
        ...prevState,
        po_date: data.purchased_date || '',
        base_price: data.base_price || '',
        source: data.source_type ? data.source_type.toUpperCase() : '',
        article_code: data.article_code || '',
        items_name: data.item_name || '',
        category: data.category || '',
        hsn: data.hsn_code || '',
        outer_type: data.outer_type || '',
        outer_size: data.outer_size || '',
        uom: data.uom || '',
        no_of_bags_purchased: data.bag_purchased || '',
        purchase_price: data.purchase_price || '',
        total_weight_purchased: data.total_weight || '',
        replacement_brand: data.replacement_brand || '',
        mrp: data.mrp || '',
        cash_discount: data.cash_discount || '',
        transport: data.transport || '',
        transport_charges: data.transport_charges || ''
      }));
    }
  }, [data]);
  



const [poAmount,setPoAmount] = useState("")
const [gstCharge,setGstCharge] = useState("")
const [apmcCharge,setApmcCharge] = useState("")

const [articleOptions, setArticleOptions] = useState("")
const [articleInfo, setArticleInfo] = useState("")

const populateArticle = () => {

  const allOptionsArticle = []
  articleInfo.forEach(item => {
      let itemName = item.itemName
      if (item.brand)
          itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ "label": itemName, "values":{"item_name":itemName,"articleCode": item.articleId, "basePrice": item.base_price, "hsnCode":item.hsn_code,"category":item.category,"variety":item.variety,"outer_size":item.lot_size}})
      
  })

  setArticleOptions(allOptionsArticle)
}


const fetchProductItem = () => {
  const productBaseInfo = [];
  productsMasterDB
    .once("value", (snapShot) => {
      const productItems = snapShot.val();

      Object.keys(productItems).forEach((productId) => {
        if (productItems[productId]["buying_flag"] == "Y") {
          productBaseInfo.push({
            articleId: productId,
            category: productItems[productId].category,
            lot_size: productItems[productId].lot_size,
            brand: productItems[productId].brand,
            variety: productItems[productId].variety,
            itemName: productItems[productId].long_description,
            base_price:
              productItems[productId].base_price &&
              productItems[productId].base_price,
            hsn_code : productItems[productId].hsn_code,
})

        }
      });
    })
    .then(() => {
      setArticleInfo(productBaseInfo);
    });
};


useEffect(() => {
  if (articleInfo)
      populateArticle()
}, [articleInfo])




useEffect(()=>{

  // invoice_amt is po amount for that item
  const getGST_Charges = () => {
    const calculatedCharge = (parseFloat(gstChargeOnItemNameChange) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedGstCharge = getGST_Charges()
  setGstCharge(calculatedGstCharge)

 const getAPMC_Charges = () => {
    const calculatedCharge = (parseFloat(apmcChargeOnItemNameChange) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedApmcCharge = getAPMC_Charges()
  setApmcCharge(calculatedApmcCharge)

},[poAmount])



function formatDates(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handleBagsReceivedChange = (value) => {
    // const bagsReceived = parseInt(value, 10);
    setProductEditObject((prev) => ({
      ...prev,
      bags_received: value,
      invoice_amt: Number(data.outer_size) * value * invoiceRatePer,
    }));
    // setBagRecieved(value);
    // setTotalWtReceived(Number(data.outer_size) * value);

    setError({ 
      replacement_brand:"",
      no_of_bags:"",
      purchase_price:""
    })

    setPurchasePrice(data.purchase_price)

    setPoAmount("")
    setGstCharge("")
    setApmcCharge("")
  };

  // Run the logic when the modal opens up
  // to reinitialize the values of all the shared states
  useEffect(() => {
    if (open) {
     handleBagsReceivedChange(parseFloat(data.bags_received) || '');
      setItemsFields({
        po_date:data.purchased_date,
        base_price:data.base_price,
        source:(data?.source_type ? data.source_type.toUpperCase() : ""),
        article_code:data.article_code,
        items_name:data.item_name || "",
        category:data.category,
        hsn:(data.hsn_code || ""),
        outer_type:(data.outer_type || ""),
        outer_size:data.outer_size,
        uom:data.uom,
        no_of_bags_purchased:data.bag_purchased,
        purchase_price:data.purchase_price,
        total_weight_purchased:data.total_weight,
        replacement_brand:data.replacement_brand,
        mrp:data.mrp,
        cash_discount:data.cash_discount,
        transport: data.transport || '',
        transport_charges: data.transport_charges || ''
      })
    }
  }, [open]);


  // making a predefined hash map items for Edit
  // all the items will be passed together in case of edit
  useEffect(()=>{
   const allEditItemshash={}
   
    for(let object of selectedPO_Item)
    {

    
      const obj={
        // this should be identified because of new values total_weight_received and grn_date
        // the new values shold not be updated
        type:"non-editable",

        // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
        outer_size:object.outer_size,
        bag_purchased:object.bag_purchased,
        purchase_price:object.purchase_price,
        apmc_charges:object.apmc_charges,
        gst_charges:object.gst_charges,

        bags_received:object.bags_received,
        base_price:object.base_price,
        bill_submitted:object.bill_submitted,
        diff_receiving:object.diff_receiving,
        expiry_date:object.expiry_date,
       // grn_date: object.grn_date,       //formatDates(new Date()), // new values should not be entered here
        invoice_amt:object.invoice_amt,
        isAdmin:validateIsAdminUser(
          sessionStorage.getItem(LOGGED_USER_EMAIL)
        ),                       // sessionStorage.getItem(LOGGED_USER_EMAIL),
        item_id:object.article_code,
        // old_grn_date:object.grn_date, // requires old value
        old_total_weight_received:object.total_weight_received, // requires old value
        payment_date:object.payment_date,
        unique_key:object.uniqueKey,
        // purchased_date:object.purchased_date,
        purchased_date:po_date,
        replacement_brand:object.replacement_brand,
        total_weight_received:object.total_weight_received,  // new values should not be entered here
        updated_at:object.updated_at,
        updated_by:object.updated_by,

        po_amount:object.po_amount, // po amount at item level
        
        gstPerFromFb:object.gstPerFromFb, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        apmcPerFromFb:object.apmcPerFromFb,
        gst_charges_per:object.gst_charges_per, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        apmc_charges_per:object.apmc_charges_per,
        indexVal:object.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
       }

      const keyValue=object.indexVal
      allEditItemshash[keyValue]=obj
    }
    setAllUpdatingEditItemsHash(allEditItemshash)
  },[])


 
  useEffect(()=>{
    const addedItemsListOfObjects=[]
    for(let key in allUpdatingEditItemsHash)
    {
        // making list of objects from hash map removing keys and only storing the values
        addedItemsListOfObjects.push(allUpdatingEditItemsHash[key])
    }
    setAllEditItemDetails(addedItemsListOfObjects)
  },[allUpdatingEditItemsHash])

 
   // invoice_amt is po amount for that item
  const getGSTCharges = () => {
    let gst_per = data.gst_charges_per
      ? Number(data.gst_charges_per.split("%")[0] || data.gstPerFromFb) // || data.gst_charges_per
      : 0;
      
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.invoice_amt);

    return (gst_per * invoice_amt) / 100;
  };

  // const getAPMCCharges = () => {
  //   let apmc_per = data.apmc_charges_per
  //     ? Number(data.apmc_charges_per.split("%")[0]  || data.apmcPerFromFb) // || data.apmc_charges_per
  //     : 0;
   
  //   let invoice_amt = productEditObject?.invoice_amt
  //     ? Number(productEditObject.invoice_amt)
  //     : Number(data.invoice_amt);
  //   return (apmc_per * invoice_amt) / 100;
  // };

  // const getTransportCharges = () => {
  //   let transport_charge = productEditObject?.transport_charges
  //     ? productEditObject.transport_charges
  //     : data.transport_charges
  //     ? data.transport_charges
  //     : 0;
  //   return Number(transport_charge) * totalWtReceived;
  // };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const updatePurchaseDetailsNew = async () => {

    if(!bagPurchased)
    return  toast("Enter Bags Purchased", { type: toast.TYPE.ERROR });

    if(!purchasePrice)
    return  toast("Enter Purchase Price", { type: toast.TYPE.ERROR });

    if(!itemsFields.po_date)
    return  toast("Select PO Date", { type: toast.TYPE.ERROR });


    // if the transport charge exists for that item then that cannot be submitted as empty
    if (items_transport_charges.current && parseFloat(items_transport_charges.current) > 0) {
    if(!itemsFields.transport_charges)
    return  toast("Enter the transport Charges", { type: toast.TYPE.ERROR });
    }

    // if(!bagRecieved || parseFloat(bagRecieved)<0)
    // {
    //   setError({replacement_brand:"",no_of_bags:"No of bags is required and it cannot be less than 0."});
    //   return toast("Enter Bag Received", { type: toast.TYPE.ERROR });
    // }
    // else if (!replaceBrand) {
    //   setError({no_of_bags:"",replacement_brand:"Replacement Brand is required."});
    //   return toast("Enter Replacement Brand", { type: toast.TYPE.ERROR });
    // } 
    // else if(!purchasePrice)
    // { 
    //   setError({no_of_bags:"",replacement_brand:"",purchase_price:"Purchase Price is required"});
    //   return toast("Enter Purchase Price", { type: toast.TYPE.ERROR });
    // }
    // else {
    //   setError('');
    //   console.log('Form submitted');
    // }

    setUpdateGrn(false);
    console.log(productEditObject);
    let newSupplier = true;


    if (productEditObject["supplier_name"]) {
      suppliersList.forEach((item) => {
        if (productEditObject["supplier_name"] == item.value) {
          newSupplier = false;
        }
      });
      if (newSupplier)
        SuppliersDB.push({ supplier_name: productEditObject["supplier_name"] });
    }

    const firebaseItemCode = itemCode.substring(11);

    console.log(
      "firebaseItemCode: ",
      firebaseItemCode,
      " itemCode: ",
      itemCode,
      "productEditObject: ",
      productEditObject,
      " materialReceivingDt: ",
      materialReceivingDt,
      " productEditObject: ",
      productEditObject
    );

    const materialReceivingDate = new Date(materialReceivingDt);
    const year = materialReceivingDate.getFullYear();
    const month =
      materialReceivingDate.getMonth().toString().length === 1
        ? `0${materialReceivingDate.getMonth() + 1}`
        : materialReceivingDate.getMonth() + 1;
    const date =
      materialReceivingDate.getDate().toString().length === 1
        ? `0${materialReceivingDate.getDate()}`
        : materialReceivingDate.getDate();

    const grn_date_format = `${year}-${month}-${date}`;

    console.log(
      "grn_date_format: ",
      grn_date_format,
      " purchase_date: ",
      data.purchased_date
    );

    const isAdmin = validateIsAdminUser(
      sessionStorage.getItem(LOGGED_USER_EMAIL)
    );

    console.log("isAdmin: ", isAdmin);

    const grn_date_obj = new Date(grn_date_format);
    const purchased_date_obj = new Date(data.purchased_date);
    const grn_date_millis = grn_date_obj.getTime();
    const purchased_date_millis = purchased_date_obj.getTime();
    let fourDaysOlderDate = new Date();

    const currYear = fourDaysOlderDate.getFullYear();
    const currMonth =
      fourDaysOlderDate.getMonth().toString().length === 1
        ? `0${fourDaysOlderDate.getMonth() + 1}`
        : fourDaysOlderDate.getMonth() + 1;
    const currDate =
      fourDaysOlderDate.getDate().toString().length === 1
        ? `0${fourDaysOlderDate.getDate()}`
        : fourDaysOlderDate.getDate();

    fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    fourDaysOlderDate = fourDaysOlderDate.setDate(
      fourDaysOlderDate.getDate() - 4
    );

    let last30DaysDate = new Date();
    last30DaysDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    last30DaysDate = last30DaysDate.setDate(last30DaysDate.getDate() - 30);
    if (!isAdmin) {
      if (new Date(grn_date_format) < new Date(last30DaysDate)) {
        return toast("GRN Date can not be more than 30 days old", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    let payment_terms = Number(data.payment_terms);
    let payment_date = moment(new Date(data.grn_date))
      .clone()
      .add(payment_terms, "day");
    const formattedPaymentDate = payment_date.format("YYYY-MM-DD");

    

    const jsonObj = {
      ...productEditObject,
      invoice_amt:poAmount || data.po_amount,
      // bags_received: bagRecieved,            
      // diff_receiving: diffWtRef.current.value,
      // total_weight_received: totalReceivedWt.current.value,
      bill_submitted: billSubmitConfirm !== "" ? "" + billSubmitConfirm : "",
      base_price: itemsFields.base_price,
      replacement_brand: itemsFields.replacement_brand,
      updated_by: UserEmail(),
      updated_at: `${UpdatedDate()} ${UpdatedTime()}`,
      isAdmin: isAdmin,
      // purchased_date: itemsFields.po_date,
      purchased_date:po_date,
      unique_key: data.uniqueKey,
      payment_date: formattedPaymentDate,
      expiry_date: productEditObject?.expiry_date
        ? productEditObject.expiry_date
        : `${
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate()
          }-${
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1
          }-${new Date().getFullYear()}`,
    };

    const old_grn_date = data.grn_date;
    const old_total_weight_received = data.total_weight_received;

   // jsonObj.grn_date = grn_date_format;
    jsonObj.old_grn_date = old_grn_date;
    jsonObj.old_total_weight_received = old_total_weight_received;
    // jsonObj.item_id = itemsFields.article_code
    jsonObj.article_code = itemsFields.article_code

    // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
    jsonObj.outer_size=itemsFields.outer_size
    jsonObj.bag_purchased=itemsFields.no_of_bags_purchased

    jsonObj.purchase_price=itemsFields.purchase_price
    jsonObj.apmc_charges=apmcCharge // || data.apmc_charges
    jsonObj.gst_charges=gstCharge // || data.apmc_charges

    // (Number(purchasePrice) * Number(totalWtReceived))
    jsonObj.po_amount=poAmount || data.po_amount

    jsonObj.gstPerFromFb=gstChargeOnItemNameChange // || data.gstPerFromFb // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
    jsonObj.apmcPerFromFb=apmcChargeOnItemNameChange // || data.apmcPerFromFb
    jsonObj.gst_charges_per=gstChargeOnItemNameChange  // || data.gst_charges_per // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
    jsonObj.indexVal=data.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
    // these objects are editable objects
    jsonObj.apmc_charges_per = apmcChargeOnItemNameChange // || data.apmc_charges_per 
    jsonObj.type="editable"
    
    if(jsonObj.grn_no)
    {
    delete jsonObj.grn_no
    }


    jsonObj.category = itemsFields.category
    jsonObj.mrp= itemsFields.mrp
    jsonObj.cash_discount = itemsFields.cash_discount
    jsonObj.total_weight = itemsFields.total_weight_purchased
    jsonObj.uom = itemsFields.uom
    jsonObj.outer_type = itemsFields.outer_type
    jsonObj.item_name = itemsFields.items_name
    jsonObj.hsn_code = itemsFields.hsn
    jsonObj.source_type = itemsFields.source
    

    jsonObj.transport = itemsFields.transport
    jsonObj.transport_charges =itemsFields.transport_charges

    console.log("Bande ka check obj", jsonObj);
    // const url = `/api/superzop/commercial/purchasedetails/addeditgrn?type=edit`;
    // baseUrl
    //   .post(url, jsonObj)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setProductEditObject({});
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
    //       notifyUser(true);
    //     }
    //   })
    //   .catch((error) => {
    //     toast("Failed to update System Inventory", { type: toast.TYPE.ERROR });
    //     console.log(error);
    //   });
    
    // newly modified objects will replace the old objects
    setAllUpdatingEditItemsHash({...allUpdatingEditItemsHash,[data.indexVal]:{...jsonObj}})
    setEditSaveClick(true)
    window.alert("Saved Successfully")
    onClose()
  }; 

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast(`Updated the GRN for ${data.article_code}`, {
        type: toast.TYPE.SUCCESS,
      });
    }
    return toast("Couldn't update the GRN", { type: toast.TYPE.ERROR });
  };
  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({
          name: suppliersList[supplier].supplier_name,
          email: suppliersList[supplier].EmailID,
          key: suppliersList[supplier].supplier_id,
          fssai: suppliersList[supplier].fssai,
          gstin: suppliersList[supplier]["GST_Identification_Number_(GSTIN)"],
          phone: suppliersList[supplier].MobilePhone,
          address: suppliersList[supplier].Billing_Address,
          street: suppliersList[supplier].Billing_Street2,
          city: suppliersList[supplier].Billing_City,
          pin: suppliersList[supplier].Billing_Code,
          state: suppliersList[supplier].Billing_State,
        });
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({
          value: items.name,
          label: items.name,
          email: items.email,
          key: items.key,
          fssai: items.fssai,
          gstin: items.gstin,
          phone: items.phone,
          address: items.address,
          street: items.street,
          city: items.city,
          pin: items.pin,
          state: items.state,
        });
      });
    }).then(() => {
      setSuppliersList(suppliers);
    });
  };





  const fetchInvoiceNumber = () => {
    const invoiceNumberList = [];
    let allInvoiceNumbers = [];

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("fetchInvoiceNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    baseUrl
      .get(url)
      .then((res) => {
        console.log("fetchInvoiceNumber: ", res);
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        Object.keys(purchaseList).forEach((items) => {
          if (purchaseList[items].invoice_number)
            allInvoiceNumbers.push(purchaseList[items].invoice_number);
        });
        allInvoiceNumbers = [...new Set(allInvoiceNumbers)];
        allInvoiceNumbers.forEach((invoices) => {
          invoiceNumberList.push({ value: invoices, label: invoices });
        });
      })
      .then(() => {
        console.log(
          "fetchInvoiceNumber invoiceNumberList: ",
          invoiceNumberList
        );
        setInvoiceNoList(invoiceNumberList);
      });
  };

  // const createGrnNumber = () => {

  //   if(!bagRecieved || parseFloat(bagRecieved)<0)
  //   {
  //     setError({replacement_brand:"",no_of_bags:"No of bags is required and it cannot be less than 0."});
  //     return toast("Enter Bag Received", { type: toast.TYPE.ERROR });
  //   }
  //   else if (!replaceBrand) {
  //     setError({no_of_bags:"",replacement_brand:"Replacement Brand is required."});
  //     return toast("Enter Replacement Brand", { type: toast.TYPE.ERROR });
  //   } 
  //   else if(!purchasePrice)
  //   { 
  //     setError({no_of_bags:"",replacement_brand:"",purchase_price:"Purchase Price is required"});
  //     return toast("Enter Purchase Price", { type: toast.TYPE.ERROR });
  //   }
  //   else {
  //     setError('');
  //     console.log('Form submitted');
  //   }

    
  //   console.log("GRN");
  //   let countCheck = 0;
  //   if (
  //     data.invoice_number &&
  //     !productEditObject["invoice_number"] &&
  //     data.supplier_name &&
  //     !productEditObject["supplier_name"]
  //   ) {
  //     setUpdateGrn(true);
  //     return;
  //   }

  //   if (data.supplier_name && !productEditObject["supplier_name"]) {
  //     setProductEditObject({
  //       ...productEditObject,
  //       supplier_name: data.supplier_name,
  //     });
  //   }

  //   if (!productEditObject["expiry_date"]) {
  //     setProductEditObject({
  //       ...productEditObject,
  //       expiry_date: formatDate(new Date()),
  //     });
  //   }


  //   const complete_date = `${selectedDate.split("-")[2]}-${
  //     selectedDate.split("-")[1]
  //   }-${selectedDate.split("-")[0]}`;

  //   console.log("createGrnNumber: ", complete_date);

  //   const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;

  //   baseUrl
  //     .get(url)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.data;
  //       }
  //     })
  //     .then((purchaseList) => {
  //       let conditionMatchGRN = false;
  //       Object.keys(purchaseList).forEach((items) => {
  //         if (
  //           productEditObject["invoice_number"] ===
  //           purchaseList[items].invoice_number
  //         ) {
  //           conditionMatchGRN = true;
  //           if (
  //             productEditObject["supplier_name"] !=
  //             purchaseList[items].supplier_name
  //           ) {
  //             toast("Supplier is not matching with Invoice Number", {
  //               type: toast.TYPE.ERROR,
  //             });
  //           }
  //           setProductEditObject({
  //             ...productEditObject,
  //             grn_no: purchaseList[items].grn_no
  //               ? purchaseList[items].grn_no
  //               : "",
  //           });
  //           setUpdateGrn(true);
  //           return;
  //         }
  //       });

  //       if (!conditionMatchGRN) {
  //         // Create GRN Number
  //         setProductEditObject({
  //           ...productEditObject,
  //           grn_no: `${selectedDate.split("-")[0]}${
  //             selectedDate.split("-")[1]
  //           }${selectedDate.split("-")[2].substring(0, 2)}${
  //             Math.floor(Math.random() * 90000) + 10000
  //           }`,
  //         });
  //         setUpdateGrn(true);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (updateGrn) {
  //     updatePurchaseDetailsNew();
  //   }
  // }, [updateGrn]);

  useEffect(() => {
    fetchSuppliers();
    fetchProductItem()
    fetchInvoiceNumber();
  }, []);


  const formatDate = (val) => {
    let date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  // trigerring the supplierInfo On suppplierName Value change that is comming from the prop/parent
  // because select here is disabled
  useEffect(() => {
    if (supplierName) {
      supplierRef.current=supplierName
      const selectedSupplier = suppliersList.find(supplier => supplier.value === supplierName);
      if (selectedSupplier) {
        setProductEditObject({
          ...productEditObject,
          supplier_name: selectedSupplier.value,
          supplier_id: selectedSupplier.key,
          supplier_email: selectedSupplier.email,
          bill_address: selectedSupplier.address,
          bill_city: selectedSupplier.city,
          bill_state: selectedSupplier.state,
          bill_street: selectedSupplier.street,
          bill_pin: selectedSupplier.pin,
          bill_fssai: selectedSupplier.fssai,
          bill_gstin: selectedSupplier.gstin,
          bill_phone: selectedSupplier.phone,
        });
      }
    }
    // window.alert(JSON.stringify(productEditObject))
  }, [supplierName, suppliersList]);

  const materialUiformatDate = (val) => {

    if(!val){
        return ''
    }

    if (typeof val === 'string' && val.includes('-')) {
        // Assuming the input is in "DD-MM-YYYY" format
        const [day, month, year] = val.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }


    // On Change the value will be
    // Wed Jul 17 2024 05:30:00 GMT+0530 (India Standard Time) {}
    if (!(val instanceof Date)) {
      val = new Date(val);
    }

    // Ensure month and day are padded with leading zeros if necessary
    const month = String(val.getMonth() + 1).padStart(2, '0');
    const day = String(val.getDate()).padStart(2, '0');
    return `${val.getFullYear()}-${month}-${day}`;
  };

  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
   transform: 'translate(-50%, -56%)',
    width: '100vw',
    height:'100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // for scrolling
    mt: 5,
    flexGrow: 1,
    display: 'flex', 
    flexDirection: 'column',
    gap: 2,
    overflow: 'hidden'
  };


  return (

      <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-grn-modal-title"
      aria-describedby="add-grn-modal-description"
    >
   <Box sx={modalStyle}>

   <Button
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        Close
      </Button>

    <Typography 
            sx={{ textAlign: 'center',
             marginBottom: 1,  
             fontWeight: 'bold'}} 
            variant="h6" 
            component="h4">
      Edit Purchase
    </Typography>

    <Divider/>

    <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
      <Table>
    <TableBody>
            <TableRow>

              <TableCell>PO Number</TableCell>
              <TableCell>
              <Tooltip title={data.po_number} arrow>
                <TextField disabled value={data.po_number} InputProps={{ readOnly: true }} fullWidth />
              </Tooltip>
              </TableCell>

              <TableCell>PO Date *</TableCell>
              <TableCell>
                <TextField type="date" 
                // value={itemsFields.po_date} 
                value = {po_date}
                onChange={(e)=>{
                 // setItemsFields({...itemsFields,po_date:e.target.value})
                 setPO_date(e.target.value)
                }}
                 inputProps={{
                  max: materialUiformatDate(new Date()), // This sets the maximum date to today
                  // min: materialUiformatDate(new Date()) // This sets the minimum date to today
                }} fullWidth
                
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#28a745',
                      borderWidth: 2,
                    },
                    '&:hover fieldset': {
                      borderColor: '#28a745',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#007bff',
                    },
                  },
                }} />
              </TableCell>

                <TableCell>Base Price</TableCell>
              <TableCell>
                <TextField disabled value={itemsFields.base_price} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

             
            
          {/* <TableCell>Supplier Name</TableCell>
          <TableCell>
      <FormControl fullWidth>
        <InputLabel id="supplier-select-label">Supplier</InputLabel>
        <Select
          disabled
          ref={supplierRef}
          labelId="supplier-select-label"
            id="supplier-select"
            // value={productEditObject.supplier_name || ''}
            value = {supplierName}
            label="Supplier"
            onChange={(event) => {
              const selectedSupplier = suppliersList.find(supplier => supplier.value === event.target.value);
                  if (selectedSupplier) {
                    setProductEditObject({
                      ...productEditObject,
                      supplier_name: selectedSupplier.value,
                      supplier_id: selectedSupplier.key,
                      supplier_email: selectedSupplier.email,
                      bill_address: selectedSupplier.address,
                      bill_city: selectedSupplier.city,
                      bill_state: selectedSupplier.state,
                      bill_street: selectedSupplier.street,
                      bill_pin: selectedSupplier.pin,
                      bill_fssai: selectedSupplier.fssai,
                      bill_gstin: selectedSupplier.gstin,
                      bill_phone: selectedSupplier.phone,
                    });
                  }
                }}
              >
                {suppliersList && suppliersList.map((supplier) => (
                  <MenuItem key={supplier.key} value={supplier.value}>
                    {supplier.value}
                  </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell> */}



            </TableRow>


            <TableRow>
                
                <TableCell>Source</TableCell>
              <TableCell>
                <FormControl >
                  <InputLabel>Source</InputLabel>
                  <Select
                    value={itemsFields.source}
                   
                    label="Source"
                    // disabled


                    onChange={(e) => {
                 
                      // Update itemsFields.items_name with the new value
                      setItemsFields(prev => ({ ...prev, 
                       source:e.target.value
                      }));
  
                    }}
                  >
                    <MenuItem value="MILL">MILL</MenuItem>
                    <MenuItem value="LOCAL PICK">LOCAL PICK</MenuItem>
                    <MenuItem value="DC DELIVERED">DC DELIVERED</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell>Article Code</TableCell>
              <TableCell>
                <TextField disabled value={itemsFields.article_code} InputProps={{ readOnly: true }}  />
              </TableCell>

              {/* <TableCell>Item Name</TableCell>    
              <TableCell>
              <Tooltip title={data.item_name} arrow>
                <TextField disabled value={data.item_name} InputProps={{ readOnly: true }} fullWidth />
                </Tooltip>
              </TableCell> */}

            <TableCell sx={{ fontSize: "0.8rem" }}>Item Name</TableCell>
            <TableCell>
              <FormControl   sx={{maxWidth:'200px'}} fullWidth >
                <InputLabel>Select Item</InputLabel>
                <Tooltip title={itemsFields.items_name} arrow>
                <Select
                  
              
                 defaultValue={itemsFields.items_name}

                 renderValue={(val) => {
                  const value = val.item_name || data.item_name
                  return value}}

                  onChange={(e) => {
                    const values = e.target.value
                    // Update itemsFields.items_name with the new value
                    setItemsFields(prev => ({ ...prev, 
                      items_name: values.item_name, 
                      article_code:values.articleCode,
                      hsn:values.hsnCode,
                      base_price:values.basePrice,
                      category:values.category,
                      outer_type:values.variety,
                      outer_size:values.outer_size,
                      total_weight_purchased:parseFloat(itemsFields.no_of_bags_purchased) * parseFloat(values.outer_size)
                    }));

                   

                    // let itemsDetails
                    // Product_Base.child(`${values.articleCode}`).once("value", snapShot => {
                    // itemsDetails = snapShot.val();
                    // })
                    // console.log(itemsDetails)
               


                  const itemNameResult = priceDetailsDB
                    .child(`${values.articleCode}`)
                    .once('value')
                    .then((snapShot) => {
                      console.log(snapShot.val());
                      const selectedItemValue = snapShot.val();
                     
                      return selectedItemValue;
                    })
                    .catch((error) => {
                      console.error('Error fetching data:', error);
                      return error;
                    });
                  
                  // Wait for the Promise to resolve before using the gstRate_ value
                  itemNameResult.then((selectedItemValue) => {


                      // Getting the New GST and APM percentage
                      const gstPercentage = parseFloat(selectedItemValue?.gst_per) || 0;
                      const apmcPercentage = parseFloat(selectedItemValue?.apmc_per) || 0;

                      setGstChargeOnItemNameChange(gstPercentage)
                      setApmcChargeOnItemNameChange(apmcPercentage)

                        //  console.log('GST Percentage:', gstPercentage);
                        // console.log('APMC Percentage:', apmcPercentage);


                    const cash_discount = selectedItemValue?.cd_per != "undefined" ?  selectedItemValue.cd_per : ""

                    let Source=""
                    if(selectedItemValue?.source_type === 'local_pick')
                    {
                      Source="LOCAL PICK"
                    }
                    else if(selectedItemValue?.source_type === 'dc_delivered')
                    {
                      Source="DC DELIVERED"
                    }
                    else
                    {
                      Source="MILL" 
                    }

                    const transport = selectedItemValue?.mill_transport ? selectedItemValue.mill_transport : "";
                    const transport_charges = selectedItemValue?.mill_transport_value ? selectedItemValue.mill_transport_value  : "";
                    items_transport_charges.current = transport_charges

                    setItemsFields((prev)=>({...prev, cash_discount:cash_discount, source:Source, purchase_price:selectedItemValue.base_price, base_price:selectedItemValue.base_price,
                   transport_charges,transport,total_transport:((transport_charges||0)*Number(itemsFields.total_weight_purchased)).toFixed(2) } )) 

                    // the purchase Price here is initially is the base price
                    setPoAmount(parseFloat(selectedItemValue.base_price)*parseFloat(itemsFields.no_of_bags_purchased)*parseFloat(values.outer_size))
                    
                  // console.log(selectedItemValue)
                  
                  
                   });

                


                    // Fetching UOM
                   const itemNameResult_2 = Product_Base
                   .child(`${values.articleCode}`)
                   .once('value')
                   .then((snapShot) => {
                     console.log(snapShot.val());
                     const selectedItemValues = snapShot.val();
                     return selectedItemValues;
                   })
                   .catch((error) => {
                     console.error('Error fetching data:', error);
                     return error;
                   });
                 
                 // Wait for the Promise to resolve before using the gstRate_ value
                 itemNameResult_2.then((selectedItemValues) => {
                  const UOM = selectedItemValues?.uom || "KG" 
                  setItemsFields((prev) => ({ ...prev, uom:UOM }))
                 // console.log(selectedItemValue)
                  });


                // Getting the New GST and APM percentage

                // const priceDetailsDBRef = priceDetailsDB.child(`${values.articleCode}`);

                // priceDetailsDBRef.once('value', (snapshot) => {
                //   const prices = snapshot.val();
                //   const gstPercentage = parseFloat(prices?.gst_per) || 0;
                //   const apmcPercentage = parseFloat(prices?.apmc_per) || 0;
                
                //   // Use the gstPercentage and apmcPercentage values here
                //   console.log('GST Percentage:', gstPercentage);
                //   console.log('APMC Percentage:', apmcPercentage);

                //   setGstChargeOnItemNameChange(gstPercentage)
                //   setApmcChargeOnItemNameChange(apmcPercentage)

                // });


                  }}

                  label="Select Item"
                >
               
                  {articleOptions && articleOptions.length > 0 ? articleOptions.map((itemName) => (
              <MenuItem key={itemName?.label || 'default-key'} value={itemName?.values || {item_name:"No Item Found in NewPurchaseEditModal.js"}}>
                {itemName?.label || ""}
              </MenuItem>
            )) : <MenuItem value="">No options available</MenuItem>}
                </Select>
                </Tooltip>
              </FormControl>
            </TableCell>



            </TableRow>




            <TableRow>

            <TableCell>Category</TableCell>
              <TableCell>
                <TextField disabled value={itemsFields.category} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>HSN</TableCell>
              <TableCell>
                <TextField disabled value={itemsFields.hsn} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>Outer Type</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Outer Type</InputLabel>
                  <Select
                    value={itemsFields.outer_type}
                    label="Outer Type"
                    disabled
                    onChange={
                      (e) => {
                      setItemsFields(prev => ({ ...prev, 
                        outer_type:e.target.value,
                      }));
                    }
                  }
                  >
                    <MenuItem value="SMALL PACK">SMALL PACK</MenuItem>
                    <MenuItem value="GONI">GONI</MenuItem>
                    <MenuItem value="BAG OF PACKS">BAG OF PACKS</MenuItem>
                    <MenuItem value="HIGH VALUE OF LOOSE">HIGH VALUE OF LOOSE</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

            </TableRow>




            <TableRow>

            <TableCell>Outer Size</TableCell>
              <TableCell>
                <TextField disabled value={`${itemsFields.outer_size} ${itemsFields?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>UOM</TableCell>
              <TableCell>
                <TextField disabled value={itemsFields.uom} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell style={{ whiteSpace: 'nowrap' }}><span>No of Bags Purchased *</span></TableCell>
              <TableCell>
                <TextField
                  inputRef={bagReceivedRef}
                  type="number"

                  error={!!errors.no_of_bags}
                  helperText={errors.no_of_bags}

                  defaultValue={itemsFields.no_of_bags_purchased}
                  onChange={(e) => {
                    // const bagsReceived = parseInt(e.target.value, 10);
                    // if (bagsReceived > data.bag_purchased) {
                    //   e.target.value = "";
                    //   toast("Bag Purchased field is required", {
                    //     type: toast.TYPE.ERROR,
                    //   });
                    //   return;
                    // }
                    setProductEditObject({
                      ...productEditObject,
                      bag_purchased: e.target.value,
                    });
                   
                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(itemsFields.purchase_price)*parseFloat(itemsFields.outer_size))

                    setBagPurchased(e.target.value);
                    setTotalWtPurchased(Number(itemsFields.outer_size) * Number(e.target.value));
                    
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt: Number(data.outer_size) * Number(e.target.value) * invoiceRatePer,
                    });

                    setItemsFields({...itemsFields,no_of_bags_purchased:parseFloat(e.target.value),
                      total_weight_purchased:parseFloat(itemsFields.outer_size) * parseFloat(e.target.value) })
                    }}


                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                  fullWidth

                />
              </TableCell>

               {/* <TableCell>Difference in Receiving</TableCell>
              <TableCell>
                <TextField
                  inputRef={diffWtRef} // woah
                  value={bagRecieved ? Number(data.total_weight) - totalWtReceived : ""}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}
             
            
            {/* 
              <TableCell>Material Receiving Date</TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={materialUiformatDate(materialReceivingDt)}
                  onChange={(e) => {
                    const newDate = e.target.value ? new Date(e.target.value) : null;
                    setMaterialReceivingDt(newDate);
                    setProductEditObject({
                      ...productEditObject,
                      grn_date: formatDate(newDate), // converts to "dd/MM/yyyy"
                    });
                  }}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}

            


            </TableRow>



            
          <TableRow>

          {/* <TableCell>Total Weight Ordered</TableCell>
              <TableCell>
                <TextField disabled value={`${data.total_weight} ${data?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
              </TableCell> */}
        
              
              <TableCell style={{ whiteSpace: 'nowrap' }}><span>Purchase Price *</span></TableCell>
              <TableCell>
                <TextField
                  type="number"

                  error={!!errors.purchase_price}
                  helperText={errors.purchase_price}

                 // defaultValue={Number(data.invoice_amt) / totalWtReceived}
                  value = {itemsFields.purchase_price}
                  onChange={(e) => {


                    setItemsFields({ ...itemsFields, 
                      purchase_price:(e.target.value),
                      // total_weight_purchased:parseFloat(itemsFields.no_of_bags_purchased)*parseFloat(itemsFields.outer_size)
                    });
                  
       
                    setInvoiceRatePer(e.target.value);

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(itemsFields.no_of_bags_purchased)*parseFloat(itemsFields.outer_size))

                    setPurchasePrice(e.target.value)
                    // setProductEditObject({
                    //   ...productEditObject,
                    //   invoice_amt: Number(e.target.value) * Number(totalWtReceived),
                    // });
                  }}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                />
                </TableCell>


                <TableCell style={{ whiteSpace: 'nowrap' }}><span>Total Weight Purchased</span></TableCell>
              <TableCell>
                <TextField
                  type="number"
                  disabled
                  // error={!!errors.purchase_price}
                  // helperText={errors.purchase_price}

                 // defaultValue={Number(data.invoice_amt) / totalWtReceived}
                  value = {itemsFields.total_weight_purchased}
                  onChange={(e) => {
                    // setInvoiceRatePer(e.target.value);

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(itemsFields.no_of_bags_purchased)*parseFloat(itemsFields.outer_size))

                    setPurchasePrice(e.target.value)
                    // setProductEditObject({
                    //   ...productEditObject,
                    //   invoice_amt: Number(e.target.value) * Number(totalWtReceived),
                    // });

                    
                  }}
                  fullWidth

                  // sx={{
                  //   '& .MuiOutlinedInput-root': {
                  //     '& fieldset': {
                  //       borderColor: '#28a745',
                  //       borderWidth: 2,
                  //     },
                  //     '&:hover fieldset': {
                  //       borderColor: '#28a745',
                  //     },
                  //     '&.Mui-focused fieldset': {
                  //       borderColor: '#007bff',
                  //     },
                  //   },
                  // }}

                />
                </TableCell>


                <TableCell style={{ whiteSpace: 'nowrap' }} ><span>Replacement Brand</span></TableCell>
              <TableCell>
                <TextField

                  onChange={(e)=>{setItemsFields({...itemsFields,replacement_brand:e.target.value})}}

                  defaultValue={itemsFields.replacement_brand}
                  error={!!errors.replacement_brand}
                  helperText={errors.replacement_brand}
                  fullWidth

                  // sx={{
                  //   '& .MuiOutlinedInput-root': {
                  //     '& fieldset': {
                  //       borderColor: '#28a745',
                  //       borderWidth: 2,
                  //     },
                  //     '&:hover fieldset': {
                  //       borderColor: '#28a745',
                  //     },
                  //     '&.Mui-focused fieldset': {
                  //       borderColor: '#007bff',
                  //     },
                  //   },
                  // }}

                />
              </TableCell>


             
           

                 {/* <TextField disabled value={getGSTCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
                {/* <TableCell>GST Amount</TableCell>
              <TableCell>
             
                <TextField disabled value={gstCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell> */}
            

              </TableRow>



              <TableRow>

              
                {/* <TextField disabled value={getAPMCCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
               {/* <TableCell>APMC Amount</TableCell>
              <TableCell>
              
                <TextField disabled value={apmcCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell> */}
          
              {/* <TableCell>Final Invoice Rate (per kg/pc)</TableCell>
              <TableCell>
                <TextField
                  value={(
                    (Number(productEditObject?.invoice_amt || data.invoice_amt) +
                      getGSTCharges() +
                      getAPMCCharges() +
                      getTransportCharges() -
                      Number(productEditObject?.scheme_amount || 0)) /
                    totalWtReceived
                  ).toFixed(2)}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}
             

                {/* <TableCell>Invoice Amount</TableCell>
              <TableCell>
                <TextField
                 // value={productEditObject?.invoice_amt || data.invoice_amt}
                  value={poAmount}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}

              </TableRow>





              <TableRow>

               

              {/* <TableCell>Total Weight Received</TableCell>
              <TableCell>
                <TextField
                 disabled
                  inputRef={totalReceivedWt}
                  value={totalWtReceived}
                  onChange={(e) => {
                    setTotalWtReceived(Number(e.target.value));
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt: Number(e.target.value) * invoiceRatePer,
                    });
                  }}
                  fullWidth
                />
              </TableCell> */}

             

              {/* <TableCell>Scheme Amount</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      scheme_amount: e.target.value,
                    })
                  }
                  fullWidth
                />
              </TableCell> */}

               
                  
              </TableRow>





              {/* <TableRow> */}

              {/* <TableCell>Invoice Date</TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={materialUiformatDate(invoiceDate) || (data.invoice_date ? materialUiformatDate(data.invoice_date) : materialUiformatDate(new Date(data.invoice_date)))}
                  onChange={(e) => {
                    setProductEditObject({
                      ...productEditObject,
                      invoice_date: e.target.value,
                    });
                    setInvoiceDate(e.target.value);
                  }}
                  InputProps={{
                    inputProps: { 
                     // min: data.purchased_date
                     max :  materialUiformatDate(new Date())
                    }
                  }}
                  fullWidth
                />
              </TableCell> */}

           
             

           


              {/* </TableRow> */}




              <TableRow>

          
              {/* <TableCell>Invoice Number</TableCell>
              <TableCell>
                <Creatable
                  options={invoiceNoList}
                  placeholder={data.invoice_number}
                  onChange={(val) =>
                    setProductEditObject({
                      ...productEditObject,
                      invoice_number: val.value,
                    })
                  }
                  styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                />
              </TableCell> */}

{/* <TableCell>Invoice Number</TableCell>
      <TableCell>
        <Autocomplete
         fullWidth
          options={invoiceNoList}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField
           // style={{ width: '100%' }}
           // fullWidth
              {...params}
              required
              placeholder={data.invoice_number}
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            setProductEditObject({
              ...productEditObject,
              invoice_number: newValue ? newValue.value : '',
            });
          }}
          // freeSolo
          // style={{ width: "100px" }}
        />
      </TableCell> */}



{/* <TableCell>Final Invoice Amount</TableCell>
              <TableCell>
                <TextField
                  value={
                    Number(productEditObject?.invoice_amt || data.invoice_amt) +
                    getGSTCharges() +
                    getAPMCCharges() +
                    getTransportCharges() -
                    Number(productEditObject?.scheme_amount || 0)
                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}

       


              {/* <TableCell>Location</TableCell>
              <TableCell>
                <TextField
                  defaultValue={data.location}
                  onChange={(e) => {
                    setProductEditObject({
                      ...productEditObject,
                      location: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </TableCell> */}


              {/* <TableCell>Remark</TableCell>
              <TableCell>
                <TextField
                  defaultValue={data.remark}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      remark: e.target.value,
                    })
                  }
                  fullWidth
                />
              </TableCell> */}


              </TableRow>






              <TableRow>
             

              <TableCell>MRP</TableCell>
              <TableCell>
                <TextField
                  defaultValue={itemsFields.mrp}
                  onChange={(e) =>
                    setItemsFields({...itemsFields,mrp:e.target.value})
                  }
                  fullWidth
                />
              </TableCell>

              {(itemsFields.source && itemsFields.source.toLowerCase() === "mill") && (
                <>
              <TableCell>Cash Discount</TableCell>
              <TableCell>
                <TextField value={itemsFields.cash_discount} onChange={(e)=>{setItemsFields({...itemsFields,cash_discount:e.target.value})}} fullWidth />
              </TableCell>
              </>
              )
                }



              </TableRow>



            {/*For Dynamic Data */}
             {/* should create different tablerows for each dynamic render */}
            <TableRow>

            {data.category && data.category.toUpperCase() === "FRESH" && (
              <>
               
                  <TableCell>Batch No</TableCell>
                  <TableCell>
                    <TextField
                      value={data.batch_no}
                      onChange={(e) =>
                        setProductEditObject({
                          ...productEditObject,
                          batch_no: e.target.value,
                        })
                      }
                      fullWidth
                    />
                  </TableCell>
              
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={expiryDate || data.expiry_date || ''}
                      onChange={(e) => {
                        setExpiryDate(e.target.value);
                        setProductEditObject({
                          ...productEditObject,
                          expiry_date: e.target.value,
                        });
                      }}
                      InputProps={{
                        inputProps: { 
                          min: new Date().toISOString().split('T')[0]
                        }
                      }}
                      fullWidth
                    />
                  </TableCell>
              </>
            )}



          {itemsFields.transport && (
              <>
                <TableCell>Transport</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Transport</InputLabel>
                    <Select

                  renderValue={(val) => {
                    const value = val || data.transport
                    return value}}
                    defaultValue={itemsFields.transport ? itemsFields.transport.toUpperCase() : ""}
                     // value={itemsFields.transport ? itemsFields.transport.toUpperCase() : ""}
                      onChange={(e)=>{ setItemsFields({...itemsFields,transport:(e.target.value)}) 
                        }}
                      label="Transport"
                      // disabled
                    >
                      <MenuItem value="EXCLUDED">EXCLUDED</MenuItem>
                      <MenuItem value="INCLUDED">INCLUDED</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                </>
            )}


      {( (itemsFields.source && itemsFields.source.toLowerCase() === "mill")  &&    (itemsFields.transport && (itemsFields.transport).toLowerCase() === "excluded") )
      && (
              <>
             
                  <TableCell>Total Transport</TableCell>
                  <TableCell>
                    <TextField
                      // placeholder={parseFloat(data.transport_charges * data.total_weight).toFixed(2)}
                      defaultValue={parseFloat(Number(itemsFields.transport_charges) * Number(itemsFields.total_weight_purchased)).toFixed(2)}
                      value = {itemsFields.total_transport}
                      onChange={(e) =>
                      {
                        setProductEditObject({
                          ...productEditObject,
                          total_transport: e.target.value,
                          transport_charges: Number(e.target.value) / Number(data.total_weight),
                        })

                        setItemsFields({...itemsFields,transport_charges:(Number(e.target.value) / Number(itemsFields.total_weight_purchased)).toFixed(2), total_transport : e.target.value } )
                      }}
                      fullWidth
                    />
                  </TableCell>
               
              
                  <TableCell style={{ whiteSpace: 'nowrap' }}><span>Mill Transport (Per kg/pc) *</span></TableCell>
                  <TableCell>
                    <TextField

                        renderValue={(val) => {
                          const value = itemsFields.transport_charges || val || data.transport_charges
                          return value}}

                       defaultValue={itemsFields.transport_charges || (data.transport_charges || "")}
                      
            
                       value={itemsFields.transport_charges}
                      onChange={(e)=>{
                      const total_transport = parseFloat(Number(e.target.value) * Number(itemsFields.total_weight_purchased)).toFixed(2)
                      setItemsFields({...itemsFields,transport_charges:e.target.value,total_transport:total_transport})
                    
                    }}
                      // InputProps={{ readOnly: true }}
                      fullWidth
                      // disabled
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#28a745',
                            borderWidth: 2,
                          },
                          '&:hover fieldset': {
                            borderColor: '#28a745',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#007bff',
                          },
                        },
                      }} />
                  </TableCell>
               
              </>
            )}

            </TableRow>


      </TableBody>
      </Table>
      </TableContainer> 

    <Divider />
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
     <Button sx={{ mr: 1 }} onClick={() => updatePurchaseDetailsNew()} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
    </Box>

   </Box>
    
    </Modal>

  );
}

export default EditGRNModal;
