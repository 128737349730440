import React, { useState, useEffect, useRef } from 'react'
import { Table, Form, Alert,Dropdown,DropdownButton, Button,Modal } from 'react-bootstrap'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {Switch} from '@material-ui/core'
import firebase from '../../../service/firebaseConfig';
import {support_portal_url} from '../../../service/servicesConfig';
import { WITHOUT_WAREHOUSE_USER_ACCESS } from "../../../constants/allConstants";

function EditUserModalPopup({selectedUser,...props}) {
  console.log("EditUserModalPopup");
  console.log(selectedUser);
  let isUserActive=true;
  if(selectedUser.status){
    if(selectedUser.status==="N"){
      isUserActive=false;
    }
  }

  let otpBypass=selectedUser.otp_bypass==false?selectedUser.otp_bypass:true;


  const [userDetailsObject,setUserDetailsObject] = useState({});
  let usertype=selectedUser.user_type?selectedUser.user_type:"";
  const [userType,setUserType] = useState(usertype);
  const [userActive,setUserActive] = useState(isUserActive);
  const [userOtpBypass,setUserOtpBypass] = useState(otpBypass);
  const [usertypeList,setUsertypeList] = useState([]);

  const [userWarehouse,setUserWarehouse] = useState(selectedUser.city);
  const [userWarehouseList,setUserWarehouseList] = useState([]);


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
      firebase.database().ref(`User_Role_Access/DeliveryPortal`).once('value').then((snapshot) => {
        if(snapshot.hasChildren()){
          const userroledata = snapshot.val();
          let newUserTypes=Object.keys(userroledata);
          let adminUser=["admin"];
          setUsertypeList(adminUser.concat(newUserTypes));
        }else{
          setUsertypeList([]);
        }
      });

      firebase.database().ref(`/Warehouses`).once('value').then((snapshot) => {
        if(snapshot.hasChildren()){
          const warehouses = snapshot.val();
          let allWarehouses=Object.keys(warehouses);
    
          setUserWarehouseList([...allWarehouses]);
        }else{
          setUserWarehouseList([]);
        }
      });

    },[])

    const updateUserDetails = () => {
      let userObj={};
      userObj['mobile']=userDetailsObject.mobile?userDetailsObject.mobile:selectedUser.mobile;
      userObj['email']=userDetailsObject.email?userDetailsObject.email:selectedUser.email;
      userObj['password']=userDetailsObject.password?userDetailsObject.password:selectedUser.password;
      userObj['status']=userActive==true?"Active":"N";
      userObj['user_type']=userType?userType:selectedUser.user_type;
      userObj['id']=selectedUser.id;
      userObj['otp_bypass']=userOtpBypass!==undefined?userOtpBypass:selectedUser.otp_bypass;
      userObj['warehouse']=userWarehouse

      console.log(userObj);
      let mobileNum=userObj.mobile;

      if(Object.keys(userObj).length==0){
        toast("Please enter email and password.", { type: toast.TYPE.ERROR });
      }else if(mobileNum==""){
        toast("Please enter mobile.", { type: toast.TYPE.ERROR });
      }else if(mobileNum.length!=10){
        toast("Please enter valid mobile number.", { type: toast.TYPE.ERROR });
      }else if(userObj.email==""){
        toast("Please enter email.", { type: toast.TYPE.ERROR });
      }else if(userObj.password==""){
        toast("Please enter password.", { type: toast.TYPE.ERROR });
      }else if(userObj.user_type==""){
        toast("Please select usertype.", { type: toast.TYPE.ERROR });
      }
      else if(!userWarehouse && !WITHOUT_WAREHOUSE_USER_ACCESS.includes(userType.trim().toLowerCase()))
        {
          toast("Please select userWarehouse.", { type: toast.TYPE.ERROR });
        }
      else
      {
        fetch(`${support_portal_url}/api/deliveryportaluser`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: JSON.stringify(userObj)
        }).then(res => {
          return res.json();
        }).then(data => {
          console.log(data);
          if(data["success"]==1){
            toast("Updated user details.", { type: toast.TYPE.SUCCESS });
            props.onHide();
            props.callback(userObj);
          }else{
            let errMessage=data['message']?data['message']:"";
            toast("Error in updating user : "+errMessage, { type: toast.TYPE.ERROR });
            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }
          }
        }).catch(err => {
          toast("Error in updating user : "+err, { type: toast.TYPE.ERROR });
        })
      }
    }

    return (<>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Update User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                  <tbody>
                      <tr>
                          <td>Mobile</td>
                          <td><Form.Control type="tel" defaultValue={selectedUser.mobile} pattern="[1-9]{1}[0-9]{9}" required onChange={(e) => {setUserDetailsObject({ ...userDetailsObject, "mobile": (e.target.value) })}} /></td>
                      </tr>
                      <tr>
                          <td>Email</td>
                          <td><Form.Control type="text" defaultValue={selectedUser.email} onChange={(e) => {setUserDetailsObject({ ...userDetailsObject, "email": (e.target.value) })}} /></td>
                      </tr>
                      <tr>
                          <td>Password</td>
                          <td><Form.Control type="text" defaultValue={selectedUser.password} onChange={(e) => setUserDetailsObject({ ...userDetailsObject, "password": (e.target.value) })} /></td>
                      </tr>
                      <tr>
                          <td>Usertype</td>
                          <td>
                              <InputGroup>
                                <DropdownButton as={InputGroup.Append} variant="outline-primary" title={userType?userType:"SELECT"}>
                                  {(usertypeList.length > 0)? usertypeList.map((usertype,index) => (
                                    <Dropdown.Item href="#" key={index} name={usertype} onClick={(e)=>{e.preventDefault();setUserType(e.target.name)}}>{usertype}</Dropdown.Item>
                                  )):null }
                                </DropdownButton>
                              </InputGroup>
                          </td>
                      </tr>

                      <tr>
                      <td>Select Warehouse</td>
                      <td>
                        <InputGroup>
                          <DropdownButton 
                            as={InputGroup.Append} 
                            variant="outline-primary" 
                            title={userWarehouse || "SELECT"}
                          >
                            {userWarehouseList.length > 0 && userWarehouseList.map((warehouse, index) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() => setUserWarehouse(warehouse)}
                              >
                                {warehouse}  {/* This was showing userWarehouse instead of warehouse */}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </InputGroup>
                      </td>
                    </tr>

                      <tr>
                          <td>Status</td>
                          <td><Switch name="status" color="primary" checked={userActive} onChange={(e)=>{setUserActive(e.target.checked)}} /></td>
                      </tr>
                      <tr>
                          <td>OTP Bypass</td>
                          <td><Switch name="otp_bypass" color="primary" checked={userOtpBypass} onChange={(e)=>{setUserOtpBypass(e.target.checked)}} /></td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={updateUserDetails}>Update User</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditUserModalPopup