import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Alert,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Button,
  Form,
  Modal,
  Badge,
  Navbar,
  InputGroup
} from "react-bootstrap";
import {
  InputGroupAddon,
  Input,
  FormFeedback,
  InputGroupText,
  CardHeader,
  CardTitle,
  CardText,
  Dropdown as Dropdownreactstrap, DropdownToggle, DropdownMenu, DropdownItem,ButtonDropdown
} from "reactstrap";
import {
  Text,
  Grid,
  Select as MantineSelect,
  SimpleGrid,
  MultiSelect,
  TextInput,
  NumberInput,
  Center,
  Space,
  Table as TableVal,
  Switch,
} from "@mantine/core";



import { validateDCManagerUser, validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import { FaWindows, FaLessThanEqual } from "react-icons/fa";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import baseURL from "../../service/servicesConfig";
import { deliveryCommercialServer } from "../../service/servicesConfig";
import AddSupplier from "./AddSupplier";

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);
const SuppliersDB = database.database().ref(`Suppliers`);
const productsMasterDB = database.database().ref(`Product_Master_New/A`);
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
function Add_Purchase(props) {
  const [suppliersList, setSuppliersList] = useState();
  const [addNewRecord, setAddNewRecord] = useState(false);
  const [submitDetails, setSubmitDetails] = useState(false);
  const [purchasedDate, setPurchasedDate] = useState("");
  const [category, setCategory] = useState("");
  const [articleCode, setArticleCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemSelection, setItemSelection] = useState("");
  const [outerType, setOuterType] = useState("");
  const [outerSize, setOuterSize] = useState("");
  const [isInvoiceFieldDisabled, setIsInvoiceFieldDisabled] = useState(false);
  const [bagPurchased, setBagPurchased] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [eachPurchaseData, setEachPurchaseData] = useState("");
  const [productBaseData, setProductBaseData] = useState({});
  const [articleInfo, setArticleInfo] = useState("");
  const [articleOptions, setArticleOptions] = useState("");
  const [hsn,setHSN] = useState("");
  const [Source, setSource] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTransportList, setIsOpenTransportList] = useState(Array(articleOptions.length).fill(false));
  const [isOpenSourceList, setIsOpenSourceList] = useState(Array(articleOptions.length).fill(false)); //useState(false);
  const [isOpenItemNameList, setIsOpenItemNameList] =useState(Array(articleOptions.length).fill(false)); //useState(false);
  const [isOpenList, setIsOpenList] = useState(Array(articleOptions.length).fill(false));
  const [error,setError] = useState({supplierError:""})
  // const [itemSelections, setItemSelections] = useState(Array(articleOptions.length).fill(null));

  const [apmcCharges,setAPMCcharge] = useState({})

  useEffect(()=>{

    if(error.supplierError)
    {
      toast(error.supplierError, {
        type: toast.TYPE.ERROR,
      })

      setError({supplierError:""})
    }

  },[error.supplierError])



  const toggleDropdown = (index) => {
    // setIsOpen(!addedItem.isOpen);
    const newIsOpenList = [...isOpenList];
    newIsOpenList[index] = !newIsOpenList[index];
    setIsOpenList(newIsOpenList);
  };

  const toggleDropdownTransport = (index) => {
    let newIsOpenTransportList = [...isOpenTransportList]
    newIsOpenTransportList[index] = !newIsOpenTransportList[index];
    setIsOpenTransportList(newIsOpenTransportList);
  };

  const toggleDropdownSource = (index) => {
    let newIsOpenSourceList = [...isOpenSourceList];
    newIsOpenSourceList[index] = !newIsOpenSourceList[index];
    setIsOpenSourceList(newIsOpenSourceList);
  };

  const toggleDropdownItemName = (index) => {
    let newIsOpenItemNameList = [...isOpenItemNameList];
    newIsOpenItemNameList[index] = !newIsOpenItemNameList[index];
    setIsOpenItemNameList(newIsOpenItemNameList);
  };


  const handleDropdownItemClickItemName = (selectedItem,addedItems,index,addedItem) =>{
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      itemSelection: selectedItem,
      itemName: selectedItem.value,
      isOpenItemName:false
    };

    //  article code will be only present at the second time item selection at the same item field
    // if(updatedItems[index].articleCode)
    // {
    // let getGstPercentage_='0'
    // priceDetailsDB.child(`${updatedItems[index].articleCode}`).once('value')
    //     .then((snapShot) => {
    //       console.log(snapShot.val());
    //       const prices = snapShot.val();
    //       getGstPercentage_=prices?.gst_per || '0'
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching data:', error);
    //     });
    // updatedItems[index].gstPercentageAtItemSelection=getGstPercentage_;
    // }

    setAddedItems(updatedItems);
    // setItemSelection(val);
    // setItemName(val.value);
    let itemName = selectedItem.value;
    let itemCode = null;
    setItemSelection(selectedItem);
    setItemName(selectedItem.value);
    // setIsOpenItemName(false);
    insertDomData(itemName, itemCode, updatedItems, index);
  }

  const handleDropdownItemClickTransport = (selectedTransport,addedItems,index,addedItem) => {
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      Transport:selectedTransport,
      // isOpenTransport:false
    };
    setAddedItems(updatedItems);
    // setTransport(e.target.name)}
    setTransport(selectedTransport);
    // setIsOpenTransport(false);
  };

  const handleDropdownItemClickSource = (selectedSource,addedItems,index, addedItem) => {
    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      Source:selectedSource,
      // isOpenSource:false
    };
    setAddedItems(updatedItems);
    setSource(selectedSource);
    // setIsOpenSource(false);
  };

  const handleDropdownItemClick = (selectedType,addedItems,index, addedItem) => {
    // const newItemSelections = [...itemSelections];
    // newItemSelections[index] = selectedType;
    // setItemSelections(newItemSelections);

    // const newItemNames = [...itemNames];
    // newItemNames[index] = selectedItem.value;
    // setItemNames(newItemNames);

    toggleDropdown(index);

    const updatedItems = [...addedItems];
    updatedItems[index] = {
      ...addedItem,
      outerType:selectedType,
      // isOpen:false
    };
    setAddedItems(updatedItems);
    setOuterType(selectedType);
    // setIsOpen(false);
  }

  const [addedItems, setAddedItems] = useState([{
    articleCode: '',
    disableList: false,
    itemSelection: null,
    itemName: '',
    category:'',
    hsn:'',
    outerType:'',
    uomValue:'',
    basePrice:'',
    purchasePrice:'',
    outerSize:'',
    bagPurchased:'',
    totalWeight:'',
    CashDisc:'',
    TransCharges:'',
    Transport:'',
    Source:'',
    replacementBrand:'',
    isOpen:false
  }]);
  const [itemDetails, setItemDetails] = useState({
    articleCode: '',
    disableList: false,
    itemSelection: null,
    itemName: '',
    category:'',
    hsn:'',
    outerType:'',
    uomValue:'',
    basePrice:'',
    purchasePrice:'',
    outerSize:'',
    bagPurchased:'',
    totalWeight:'',
    CashDisc:'',
    TransCharges:'',
    Transport:'',
    Source:'',
    replacementBrand:'',
    isOpen:false
  });

  const handleAddItem = () => {
    setAddedItems([...addedItems, { ...itemDetails }]);
    setItemDetails({
      articleCode: '',
      disableList: false,
      itemSelection: null,
      itemName: '',
      category:'',
      hsn:'',
      outerType:'',
      uomValue:'',
      basePrice:'',
      purchasePrice:'',
      outerSize:'',
      bagPurchased:'',
      totalWeight:'',
      CashDisc:'',
      TransCharges:'',
      Transport:'',
      Source:'',
      replacementBrand:''
    });
  };
 
  const [dateChange,setdateChange] = useState(new Date());
   const [invoiceDate,setinvoiceDate] = useState(`${
                          dateChange.getDate() < 10
                            ? "0" + dateChange.getDate()
                            : dateChange.getDate()
                        }-${
                          dateChange.getMonth() + 1 < 10
                            ? "0" + (dateChange.getMonth() + 1)
                            : dateChange.getMonth() + 1
                        }-${dateChange.getFullYear()}`);
  const [disableList, setDisableList] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [supplierSelection, setSupplierSelection] = useState("");
  const [supplierError, setSupplierError] = useState('');
  const [isSupplierSelectionDisabled, setIsSupplierSelectionDisabled] =
    useState(false);
  const [correspondingItems, setCorrespondingItems] = useState("");
  const [dateToModify, setDateToModify] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [basePrice, setBasePrice] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [SupplierID, setSupplierID] = useState("");
  const [SupplierEmail, setSupplierEmail] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [replacementBrand, setReplacementBrand] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [showExistingPoChecked, setShowExistingPoChecked] = useState(false);
  const [existingPoNumbersToDisp, setExistingPoNumbersToDisp] = useState([]);
  const [existingPoSelection, setExistingPoSelection] = useState({});
  const [Payment_Terms, setPayment_Terms] = useState("")
  const [Delivery, setDelivery] = useState("")
  const [exisitingPoNumbersAndSuppliers, setExistingPoNumbersAndSuppliers] =
    useState({});
      const [PoAmt, setPoAmt] = useState(Number(purchasePrice)* Number(totalWeight));
const [GstCharges, setGstCharges] = useState("");
const [Fssai, setFssai] = useState("");
const [Gstin, setGstin] = useState("");
const [phone, setPhone] = useState("");
const [Address, setAddress] = useState("");
const [Street, setStreet] = useState("");
const [City, setCity] = useState("");
const [Pin, setPin] = useState("");
const [State, setState] = useState("");
const [Apmc, setApmc] = useState("");

const [Mrp,setMrp] = useState("")




  const PurchaseData = [];
  const DateofPurchase = [];
  let purchaseItems = {};
  const categoryField = useRef();
  const itemNoField = useRef();
  const itemNameField = useRef();
  const totalWtPurchasedRef = useRef();
  const supplierRef = useRef();
  const datepickerRef = useRef();
  let btnRef = useRef();
const [Transport, setTransport] = useState("");
const [CashDisc, setCashDisc] = useState("");
const [TransCharges, setTransCharges] = useState("");
  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast("Purchase Details added", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't add the Purchase Details", {
      type: toast.TYPE.ERROR,
    });
  };

  const fetchProductItem = () => {
    const productBaseInfo = [];
    productsMasterDB
      .once("value", (snapShot) => {
        const productItems = snapShot.val();

        Object.keys(productItems).forEach((productId) => {
          if (productItems[productId]["buying_flag"] == "Y") {
            productBaseInfo.push({
              articleId: productId,
              category: productItems[productId].category,
              lot_size: productItems[productId].lot_size,
              brand: productItems[productId].brand,
              variety: productItems[productId].variety,
              itemName: productItems[productId].long_description,
              base_price:
                productItems[productId].base_price &&
                productItems[productId].base_price,
              hsn_code : productItems[productId].hsn_code,
})

          }
        });
      })
      .then(() => {
        setArticleInfo(productBaseInfo);
      });
  };

  const fetchPoList = (selectedDate) => {
    console.log("selectedDate", moment(selectedDate).format("YYYY-MM-DD"));
    if (!selectedDate) {
      return console.log("ERROR date arg not received");
    }

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    const getUrl = `/api/superzop/commercial/purchasedetails/getpolist?create_date=${formattedDate}`;
    console.log("fetch po list URL ", getUrl);
    deliveryCommercialServer
      .get(getUrl)
      .then((response) => {
        if (response.status === 200) {
          const po_list = [];
          const po_list_with_suppliers = {};
          const data = response.data;
          data.forEach((item, index) => {
            po_list.push({ value: item.po_number, label: item.po_number });
            po_list_with_suppliers[item.po_number] = {
              supplier_name: item.supplier_name,
              invoice_number: item.invoice_number || "",
            };
          });
          console.log("set this po list", data);
          setExistingPoNumbersToDisp(po_list);
          setExistingPoNumbersAndSuppliers(po_list_with_suppliers);
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.log("error occured while fetching po list");
        console.log(err);
        setExistingPoNumbersToDisp([]);
      });
  };

  useEffect(() => {
    fetchProductItem();
    fetchPoList(dateToModify);
    console.log(datepickerRef.current);
  }, []);

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        

        sortSupplierList.push({supplierGroup:(suppliersList[supplier].supplierGroup || "NA"), // if no key found then default will be NA :)
          name : suppliersList[supplier].supplier_name,email :suppliersList[supplier].EmailID,key : suppliersList[supplier].supplier_id, fssai : (suppliersList[supplier]?.fssai ? suppliersList[supplier].fssai : suppliersList[supplier].FSSAI) ,gstin:(suppliersList[supplier]['GST_Identification_Number_(GSTIN)'] ? suppliersList[supplier]['GST_Identification_Number_(GSTIN)'] : suppliersList[supplier]["Gstin"] ),phone: suppliersList[supplier].MobilePhone,address:suppliersList[supplier].Billing_Address,street:suppliersList[supplier].Billing_Street2,city:suppliersList[supplier].Billing_City,pin:suppliersList[supplier].Billing_Code,state:suppliersList[supplier].Billing_State, supplier_id:suppliersList[supplier].supplier_id,
          supplierType : (suppliersList[supplier]?.supplierType || "NA") })
      }
    
      );
      

      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        // if(!(items.supplier_id && items.supplier_id.includes('VA'))) {
        //   suppliers.push({ supplierGroup:items.supplierGroup, value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state,
        //     supplierType:items.supplierType
        //   });
        // } else if(items.supplier_id && items.supplier_id.includes('VA') && (validateDCManagerUser(sessionStorage.getItem(LOGGED_USER_EMAIL)))) {
        //   suppliers.push({ supplierGroup:items.supplierGroup, value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state,
        //     supplierType:items.supplierType
        //   });
        // }
        suppliers.push({ supplierGroup:items.supplierGroup, value: items.name, label: items.name,email : items.email,key : items.key,fssai : items.fssai,gstin:items.gstin,phone: items.phone,address:items.address,street:items.street,city:items.city,pin:items.pin,state:items.state,
          supplierType:items.supplierType
        });
      });
    }).then(() => {
      setSuppliersList(suppliers)
    });
  };

  useEffect(() => {
    fetchProductItem();
    fetchSuppliers();
    console.log(datepickerRef.current);
  }, []);

  const populateArticle = () => {
    const allOptionsArticle = [];
    articleInfo.forEach((item) => {
      let itemName = item.itemName;
      if (item.brand) itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ value: itemName, label: itemName });
    });
    setArticleOptions(allOptionsArticle);
  };

  useEffect(() => {
    if (articleInfo) populateArticle();
  }, [articleInfo]);

  const insertDomData = (itemValue, articleCode, addedItems,index) => {
    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          console.log(item);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;
          console.log(
            "Updating based on ArticleCode:: " +
              articleCode +
              " Article Name :: " +
              itemValue +
              " Category:: " +
              item.category
          )
          let updatedItems = addedItems;
          // updatedItems[index]['articleCode'] = item.articleId;
          updatedItems[index]['disableList'] = true;
          updatedItems[index]['category'] = item.category;
          updatedItems[index]['hsn'] = item.hsn_code ? item.hsn_code :'';  
          updatedItems[index]['outerType'] = item.variety;
          updatedItems[index]['itemName'] = itemValue;
          // updatedItems[index]['uomValue'] = item.uom;
          updatedItems[index]['basePrice'] = item.base_price ? item.base_price : '';
          updatedItems[index]['purchasePrice'] = item.base_price ? item.base_price : '';
          setAddedItems(updatedItems);

          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          setCategory(item.category);
          setItemName(itemValue);
          setOuterType(item.variety);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${articleCode}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            updatedItems[index]['uomValue'] = itemsDetails?.uom?.toLowerCase() == "kg" ? "KG" : "PC";
            setUomValue(itemsDetails?.uom?.toLowerCase() == "kg" ? "KG" : "PC");
            updatedItems[index]['outerSize'] = itemsDetails?.outer_size && Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1;
            setOuterSize(itemsDetails?.outer_size && Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1);
          });
          priceDetailsDB.child(`${articleCode}`).once('value').then((snapShot) => {
            console.log(snapShot.val());
            const prices = snapShot.val();
            updatedItems[index]['gstCharges'] = prices?.gst_per  ? prices.gst_per : '0';
            updatedItems[index]['apmc'] = prices?.apmc_per ? prices.apmc_per : '0';
            // setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
            setGstCharges(prices?.gst_per
              ? (typeof prices.gst_per === 'string'
                 ? (prices.gst_per.split('%')[0] || prices.gst_per)
                 : prices.gst_per.toString())
              : "0")
            setApmc(prices?.apmc_per 
              ? (typeof prices.apmc_per === 'string' 
                 ? (prices.apmc_per.split('%')[0] || prices.apmc_per) 
                 : prices.apmc_per.toString()) 
              : "0")
            if(prices?.source_type === 'local_pick')
            {
              updatedItems[index]['Source'] = 'LOCAL PICK';
              setSource("LOCAL PICK")
            }
            else if(prices?.source_type === 'dc_delivered')
            {
              updatedItems[index]['Source'] = 'DC DELIVERED';
              setSource("DC DELIVERED")
            }
            else
            {
             updatedItems[index]['Source'] = 'MILL';
             updatedItems[index]['Transport'] = prices?.mill_transport ? prices.mill_transport : "";
             updatedItems[index]['TransCharges'] = prices?.mill_transport_value ? prices.mill_transport_value  : "";
             updatedItems[index]['CashDisc'] = prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "";
             setSource("MILL")
             setTransport(prices?.mill_transport ? prices.mill_transport : "");
             setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
             setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
            }
          })

        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          console.log(
            "Updating based on ArticleName:: " +
              itemValue +
              " Article ID :: " +
              item.articleId +
              " Category:: " +
              item.category
          );

          let updatedItems = addedItems;
          // const newItemSelections = [...itemSelections];
          // itemSelections[index]['outerType'] = item.variety;
          // setItemSelections(newItemSelections);
          updatedItems[index]['articleCode'] = item.articleId;
          updatedItems[index]['category'] = item.category;
          updatedItems[index]['hsn'] = item.hsn_code ? item.hsn_code :'';  
          updatedItems[index]['outerType'] = item.variety;
          updatedItems[index]['uomValue'] = item.uom;
          updatedItems[index]['basePrice'] = item.base_price ? item.base_price : '';
          updatedItems[index]['purchasePrice'] = item.base_price ? item.base_price : '';
          setAddedItems(updatedItems);
          
          setCategory(item.category);
          item.hsn_code ? setHSN(item.hsn_code) : setHSN("");
          categoryField.current.value = item.category;
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setOuterType(item.variety);
          setUomValue(item.uom);
          item.base_price ? setBasePrice(item.base_price) : setBasePrice("");
          item.base_price
            ? setPurchasePrice(item.base_price)
            : setPurchasePrice("");
          Product_Base.child(`${item.articleId}`).once("value", (snapShot) => {
            const itemsDetails = snapShot.val();
            console.log(itemsDetails);
            if (itemsDetails) {
              updatedItems[index]['uomValue'] = itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC";
              setUomValue(itemsDetails.uom.toLowerCase() == "kg" ? "KG" : "PC");
              updatedItems[index]['outerSize'] = Number(itemsDetails.outer_size) ? Number(itemsDetails.outer_size) : 1;
              setOuterSize(
                Number(itemsDetails.outer_size)
                  ? Number(itemsDetails.outer_size)
                  : 1
              );
            }
          });
          priceDetailsDB.child(`${item.articleId}`).once('value').then((snapShot) => {
              console.log(snapShot.val());
              const prices = snapShot.val();
              updatedItems[index]['gstCharges'] = prices?.gst_per  ? prices.gst_per : '0';
              updatedItems[index]['apmc'] = prices?.apmc_per ? prices.apmc_per : '0';
              setGstCharges(prices?.gst_per  ? prices.gst_per : '0')
              setApmc(prices?.apmc_per ? prices.apmc_per : "0")
            
              if(prices?.source_type === 'local_pick')
              {
                updatedItems[index]['Source'] = 'LOCAL PICK';
                setSource("LOCAL PICK")
              }
              else if(prices?.source_type === 'dc_delivered')
              {
                updatedItems[index]['Source'] = 'DC DELIVERED';
                setSource("DC DELIVERED")
              }
              else
              {
               updatedItems[index]['Source'] = 'MILL';
               updatedItems[index]['Transport'] = prices?.mill_transport ? prices.mill_transport : "";
               updatedItems[index]['TransCharges'] = prices?.mill_transport_value ? prices.mill_transport_value  : "";
               updatedItems[index]['CashDisc'] = prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "";
               setSource("MILL")
               setTransport(prices?.mill_transport ? prices.mill_transport : "");
               setTransCharges(prices?.mill_transport_value ? prices.mill_transport_value  : "");
               setCashDisc(prices?.cd_per && prices?.cd_per != "undefined" ?  prices.cd_per : "") ;
              }
            })
        }
      });
    }
  };

  const resetValues = () => {
    setArticleCode("");
    setCategory("");
    setItemName("");
    setInvoiceNumber("");
    setExistingPoSelection(null);
    setShowExistingPoChecked(false);
    setItemSelection("");
    setSupplierSelection(null);
    setIsSupplierSelectionDisabled(false);
    setSupplier("");
    setOuterType("");
    setCategory("");
    setOuterSize("");
    setInvoiceNumber("");
    setUomValue("");
    setBasePrice("");
    setPurchasePrice("");
    setTransport("")
    setCashDisc("")
    setReplacementBrand("");
    setBagPurchased("");
    setButtonStatus(false);
    setTotalWeight("");
    setHSN("");
    setPayment_Terms("");
    setDelivery("");
    setSupplierID("");
    setSupplierEmail("");
    setMrp("");
    setSupplierError("")

    setAddedItems([{
      articleCode: '',
      disableList: false,
      itemSelection: null,
      itemName: '',
      category:'',
      hsn:'',
      outerType:'',
      uomValue:'',
      basePrice:'',
      purchasePrice:'',
      outerSize:'',
      bagPurchased:'',
      totalWeight:'',
      CashDisc:'',
      TransCharges:'',
      Transport:'',
      Source:''
    }])
  };

  const generateNewPoNumber = (selectedDate) => {
    let yearFromDate = selectedDate.split("-")[2];
    let newYearStr = yearFromDate.substr(yearFromDate.length - 2);
    let randomFiveDigitNum = new Date().getTime() % 100000;
    let po_number = `${newYearStr}${
      selectedDate.split("-")[1]
    }${selectedDate.split("-")[0]}${randomFiveDigitNum}`;
    return po_number;
  };

  const PushPurchaseDetails = (event) => {

    if (!supplierSelection) {
      event.preventDefault();
      setSupplierError('Supplier Name is required');
      return;
    }


    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;

  
    console.log("check existingPoSel", existingPoSelection);
    if (showExistingPoChecked && Object.keys(existingPoSelection).length < 2) {
      return toast("Please select an purchase order number", {
        type: toast.TYPE.ERROR,
      });
    }
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let purchaseDateValue = purchasedDate;
      setButtonStatus(true);
      console.log("source"+Source);

      let finalObj = [];

      if (purchasedDate == "") {
        if (dateToModify.getDate() < 10) {
          currentDt = "0" + dateToModify.getDate();
        } else {
          currentDt = dateToModify.getDate();
        }
        if (dateToModify.getMonth() + 1 < 10)
          currentMn = "0" + (dateToModify.getMonth() + 1);
        else {
          currentMn = dateToModify.getMonth() + 1;
        }
        purchaseDateValue = `${currentDt}-${currentMn}-${dateToModify.getFullYear()}`;
      }
      var newSupplier = true;
      suppliersList.forEach((item) => {
        if (supplier == item.value) {
          newSupplier = false;
        }
      });
      if (newSupplier) {
        SuppliersDB.push({ supplier_name: supplier });
      }
      let po_number = "";
      if (showExistingPoChecked) {
        po_number = existingPoSelection.value;
      } else {
        po_number = generateNewPoNumber(purchaseDateValue);
      }
      console.log("PurchaseDate" + purchaseDateValue);
      console.log("saved supplier name", supplier);
      // Payload_1
      let jsonObj = {
        purchased_date: purchaseDateValue,
        bill_address:Address?Address:"",
        bill_city:City?City:"",
        bill_street:Street?Street:"",
        bill_state:State?State:"",
        bill_pin:Pin?Pin:"",
        bill_gstin:Gstin?Gstin:"",
        bill_fssai:Fssai?Fssai:"",
        bill_phone:phone?phone:"",
        invoice_number: invoiceNumber,
        invoice_number:invoiceNumber,
        invoice_date:invoiceDate,
        po_number: po_number,
        supplier_name: supplier,
        supplier_email:SupplierEmail?SupplierEmail:"",
        supplier_id:SupplierID,
        delivery_in_days:Delivery?Delivery:"",
        payment_terms : Payment_Terms?Payment_Terms:"",
        mrp: Mrp,
        created_by: sessionStorage.getItem("Logged User Email Id"),//.slice(0, 19).replace('T', ' '),
        created_at: new Date().toISOString(),//.slice(0, 19).replace('T', ' '),
        updated_at: new Date().toISOString(),// .slice(0, 19).replace('T', ' '),
       // container_packing_charges:containerPackingCharges,
       // loading_unloading_charges:loadingUnloadingCharges,
       // other_charges:otherCharges,
       // gross_amount:grossAmount,
       // total_gst_charges:finalGST,
       // total_po_amount:finalPO,
      };

      // Payload_2
      addedItems.map((itemValue)=>{
        if (
          itemValue.articleCode !== "" ||
          itemValue.itemName !== "" ||
          (supplier != "" && itemValue.uomValue && itemValue.outerSize && itemValue.outerType && itemValue.Source !="undefined")
        ) {
          if (itemValue.outerType !== "") {

            const warehouse = sessionStorage.getItem("Logged User City");
          
            if (!validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && !warehouse) {
              toast("Warehouse information is missing. Please log in again.", { type: toast.TYPE.ERROR });
              return;  // Stop further execution if warehouse is missing
            }


            const randomKey = itemValue.articleCode + "-" + Math.floor(Math.random() * (999 - 100 + 1) + 100); //itemKey
            let items= {
                gst_charges_per : itemValue.gstPercentage?itemValue.gstPercentage:'0',
                gst_charges:itemValue.gstCharge,
                apmc_charges:itemValue.apmcCharge,
                apmc_charges_per:itemValue.apmc?itemValue.apmc:'0',
                category: itemValue.category?itemValue.category:"",
                hsn_code:itemValue.hsn?itemValue.hsn:"",
                article_code: itemValue.articleCode,
                base_price: itemValue.basePrice,
                purchase_price: itemValue.purchasePrice ? itemValue.purchasePrice : itemValue.basePrice,
                uom: itemValue.uomValue ? itemValue.uomValue :"",
                item_name: itemValue.itemName,
                outer_type: itemValue.outerType,
                outer_size: itemValue.outerSize,
                replacement_brand: itemValue.replacementBrand ? itemValue.replacementBrand : "",
                bag_purchased: itemValue.bagPurchased,
                total_weight: itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased,
                source_type : itemValue.Source,
                transport_charges: itemValue.TransCharges,
                cash_discount : itemValue.CashDisc,
                transport : itemValue.Transport,
                po_amount : PoAmt ? PoAmt : itemValue.purchasePrice * (itemValue.totalWeight ? itemValue.totalWeight : itemValue.outerSize * itemValue.bagPurchased),
                mrp: Mrp,
                key:randomKey,
                warehouse:warehouse
            }

            
            console.log("data to save", jsonObj);
            
            const updatedJsonObj = {...jsonObj,...items};
            PurchaseDetailsData.child(
              `${purchaseDateValue.split("-")[2]}/${
                purchaseDateValue.split("-")[1]
              }/${purchaseDateValue}`
            )
              .child(randomKey)
              .update(updatedJsonObj);

            // jsonObj.key = randomKey;
            jsonObj.updated_at = `${UpdatedDate()} ${UpdatedTime()}`;
            finalObj.push(items);
  
            // const url =
            //   "/api/superzop/commercial/purchasedetails/createpurchasedetailsrecord";
  
            // baseURL
            //   .post(url, jsonObj)
            //   .then((res) => {
            //     console.log("updatePurchaseDetails ", res);
            //     notifyUser(true);
            //     setButtonStatus(false);
            //     resetValues();
            //   })
            //   .catch((error) => {
            //     console.error("An Error has occurred ", error);
            //     notifyUser(false);
            //     setButtonStatus(false);
            //   });
  
          } else {
            return toast("Select Outer Type", { type: toast.TYPE.ERROR });
          }
        } else {
          return toast("Article Code / Outer Size / UOM / Supplier can't be empty", {
            type: toast.TYPE.ERROR,
          });
        }
      });

      jsonObj['items'] = finalObj;
      
      const articleCodeSet = new Set();

      for (const item of finalObj) {
        const articleCode = item.article_code;

        if (articleCodeSet.has(articleCode)) {
          setButtonStatus(false);
          return toast(`Duplicate Item Id found: ${articleCode}`, { type: toast.TYPE.ERROR });
        }

        articleCodeSet.add(articleCode);
      }
      
      const url = "/api/superzop/commercial/purchasedetails/createPurchaseDetailsRecordMySqlForItems";
  
            baseURL
              .post(url, jsonObj)
              .then((res) => {
                console.log("updatePurchaseDetails ", res);
                notifyUser(true);
                setButtonStatus(false);
                resetValues();
                setValidated(false);

                // making values null after successful submission
                setContainerPackingCharges(0)
                setLoadingUnloadingCharges(0)
                setOtherCharges(0)
                setGrossAmount(0)
                setTotalAPMC(0)
                setTotalAmount(0)
                setGstSum(0)
                setfinalGST(0)
                setTDS(0)
                setFinalPO(0)

              })
              .catch((error) => {
                console.error("An Error has occurred ", error);
                notifyUser(false);
                setButtonStatus(false);
              });
    }

    setValidated(true);

    
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const openListPurchase = () => {
    window.open("#/listpurchase");
  };

  const [selectedItemsGST_rates ,setSelectedItemsGST_rates] = useState({});

  const [containerPackingCharges, setContainerPackingCharges] = useState(0);
  const [loadingUnloadingCharges, setLoadingUnloadingCharges] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [totalAPMC,setTotalAPMC] = useState(0);
  const [totalAmount,setTotalAmount] = useState(0);
  const [gstSum,setGstSum] = useState(0);
  const [finalGST,setfinalGST] = useState(0);
  const [TDS,setTDS] = useState(0);
  const [finalPO,setFinalPO] = useState(0)
  const [supplierGroup,setSupplierGroup]=useState("")
  const minimumGST = useRef(100);


   // calculates Item level Amount
  function calculateItemLevelAmount(addedItem,calType) {
  
    const basePrice = Number(addedItem.purchasePrice) || 0;
    
    let totalWeight = 0

    if(calType="NoOfBagsPurchased"){
      totalWeight= Number(addedItem.bagPurchased) * Number(addedItem.outerSize);
    }
    else 
    {
      totalWeight = addedItem.totalWeight;
    }

    const calculatedAmount = basePrice * totalWeight;
    addedItem.amount = Number(calculatedAmount.toFixed(2));
    return addedItem.amount;
  }

  // calculates Item level APMC
  function calculateItemLevelAPMCCharge(addedItem) {

    const calculatedAmount = addedItem.amount

    let APMC_PER = Number(Apmc)
    if(!(addedItem.articleCode in apmcCharges))
    {
      apmcCharges[addedItem.articleCode]=Number(Apmc)
    }
    else
    {
      APMC_PER = apmcCharges[addedItem.articleCode]
    }
    
    
    const apmcCharge = APMC_PER ? (calculatedAmount / 100) * APMC_PER : 0; 
    addedItem.apmcCharge = Number(apmcCharge.toFixed(2))
    return addedItem.apmcCharge;
  }


  //  async function fetchGSTPercentage(articleCode) {
  //     try {
  //       const snapshot = await priceDetailsDB.child(`${articleCode}`).once('value');
  //       const prices = snapshot.val();
  //       const gstPercentage = parseFloat(prices?.gst_per) || 0;
  //       return parseFloat(gstPercentage);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       return 0;
  //     }
  //   }

  // calculates Items level GST and Total GST sum of items
  function calculateItemLevelGSTCharge(addedItem, addedItems, gstRate, amount) {

    function getGSTsum(addedItems)
    {
      let GST_SUM = 0
      addedItems.forEach((item) => {
        GST_SUM += parseFloat(item.gstCharge || 0);
      });
      setGstSum(parseFloat(GST_SUM.toFixed(2)));
      return parseFloat(GST_SUM.toFixed(2))
    }

    let GST_SUM = 0


    // async function fetchGSTPercentage() {
    //   try {
    //     const snapshot = await priceDetailsDB.child(`${addedItem.articleCode}`).once('value');
    //     const prices = snapshot.val();
    //     const gstPercentage = parseFloat(prices?.gst_per) || 0;
    //     addedItem.gstPercentageAtItemSelection = gstPercentage;
    //     return parseFloat(gstPercentage);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //     return 0;
    //   }
    // }

    const gstPercentage = Number(gstRate) 

    

    if (gstPercentage > 0) { // Ignoring items having 0% GST among the minimum values comparisons of GST


       // commenting this because this logic has been moved to generic section at purchase GRN

    //   if (gstPercentage < minimumGST.current) {
    //    minimumGST.current = gstPercentage; // Get the minimum value of the GST
    //     // Redo GST calculation for all the selected items because we found a new minimum
       
    //     for (let i = 0; i < (addedItems.length); i++) {
    //       // gstCharge with 0 has no GST they should not be added
    //       if (addedItems[i].gstCharge && addedItems[i].gstCharge > 0) { // for the item created for the first time it will not come inside loop because it will not gstCharge key
    //         const newGstCharges = (parseFloat(addedItems[i].amount) / 100) * minimumGST.current;
    //         addedItems[i].gstCharge = parseFloat(newGstCharges.toFixed(2));
    //         addedItems[i].gstPercentage = minimumGST.current;


    //       }
    //     }
    //  }
    //   // Calculate GST for the current added item
    //   const gstCharge = (parseFloat(amount) / 100) * minimumGST.current;
    //   addedItem.gstCharge = parseFloat(gstCharge.toFixed(2));
    //   addedItem.gstPercentage = minimumGST.current;

      const gstCharge = (parseFloat(amount) / 100) * gstPercentage //addedItem.gstPercentageAtItemSelection;
      addedItem.gstCharge = parseFloat(gstCharge.toFixed(2));
      addedItem.gstPercentage = gstPercentage
      // calculate total sum of GST (Total GST)
      GST_SUM= 0 //getGSTsum(addedItems)
      return [GST_SUM,Number(addedItem.gstCharge)];
    } else {
      addedItem.gstCharge = 0;
      addedItem.gstPercentage = 0;
      // calculate total sum of GST (Total GST)
      GST_SUM = 0 // getGSTsum(addedItems)
      return [GST_SUM,Number(addedItem.gstCharge)]; // if GST is 0 then it should be added as it is
    }
  }
  // calculates Total Amount
  function calculateTotalAmount(addedItem) {
    let totalAmount = 0;
    if (Array.isArray(addedItem)) {
      addedItem.forEach(item => {
        if (item.amount) {
          totalAmount += parseFloat(item.amount);
        }
      });
    }
    let calculatedTotalAmount = parseFloat(totalAmount.toFixed(2))
    return calculatedTotalAmount;
  }
  // calculates Total APMC amount
  function calculateTotalAPMC(addedItem) {
    let totalAmount = 0;
    if (Array.isArray(addedItem)) {
      addedItem.forEach(item => {
        if (item.apmcCharge) {
          totalAmount += parseFloat(item.apmcCharge);
        }
      });
    }

    let calculatedAPMC = parseFloat(totalAmount.toFixed(2));    
    return calculatedAPMC
  }

  // TDS amount
  const handleTDS = (e) => {
   let value = e.target.value;
   value = value === '' ? 0 : parseFloat(value)
   setTDS(value);
   return value
  };
  // After adding ContainerPackingCharges triggers recalculation of Gross Amount
  const handleContainerPackingChargesChange = (e) => {
    let value = e.target.value; // parseFloat here at empty string will give NaN
    value = value === '' ? 0 : parseFloat(value)
    setContainerPackingCharges(value);
    return handleGrossAmount(value, loadingUnloadingCharges, otherCharges, totalAPMC, totalAmount);
  };
  // After adding LoadingUnloadingChargesChange triggers recalculation of Gross Amount
  const handleLoadingUnloadingChargesChange = (e) => {
    let value = e.target.value;
    value = value === '' ? 0 : parseFloat(value)
    setLoadingUnloadingCharges(value);
    return handleGrossAmount(containerPackingCharges, value, otherCharges, totalAPMC, totalAmount);
  };
  // After adding handleOtherChargesChange triggers recalculation of Gross Amount
  const handleOtherChargesChange = (e) => {
    let value = e.target.value; 
    value = value === '' ? 0 : parseFloat(value)
    setOtherCharges(value);
    return handleGrossAmount(containerPackingCharges, loadingUnloadingCharges, value, totalAPMC, totalAmount);
  };

  // common function for Total APMC and Total Amount calculation  used only by handleGrossAmount function
  function calculateTotal(addedItem, property) {
    if (!Array.isArray(addedItem)) return 0;
    return addedItem.reduce((total, item) => {
      if (item[property]) {
        return total + parseFloat(item[property]);
      }
      return total;
    }, 0);
  }

  // Gross Amount calculation with Total sum of Total Amount and Total APMC charges
  const handleGrossAmount = (containerPackingCharges, loadingUnloadingCharges, otherCharges, totalAPMC, totalAmount ) => {
    let sum = 0;
   // Parse the values to numbers and check if they are valid
  // Convert input values to numbers

  const cpcValue = parseFloat(containerPackingCharges || '0');
  const luValue =  parseFloat(loadingUnloadingCharges || '0');
  const ocValue =  parseFloat(otherCharges || '0');

  // Check if all values are valid numbers (not NaN)
  if (!isNaN(cpcValue) && !isNaN(luValue) && !isNaN(ocValue)) {
    sum =
      cpcValue +
      luValue +
      ocValue +
      totalAPMC +
      totalAmount
      // comment totalAPMC and totalAmount and uncooment the below it will provide different accuracy
      // calculateTotal(addedItems, 'amount') +   
      // calculateTotal(addedItems, 'apmcCharge');
  }
  else{
    sum = totalAPMC + totalAmount
  }
  setGrossAmount(Number(sum))
  return Number(sum);
  };

  // calculated mimimum GST (mimmum GST on any of the items except 0 if no item with GST found then GST will be 0) charges on containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMC
  const calculateGSTforAdditionalCharges =(containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMC) =>
  {
    let calculatedAdditionalCharge = 0
    if(minimumGST.current !== 100){
      let sumOfCharges = parseFloat(containerPackingCharges) + parseFloat(loadingUnloadingCharges) + parseFloat(otherCharges) + parseFloat(totalAPMC)
      let requiredGSTpercentage = minimumGST.current
      calculatedAdditionalCharge = parseFloat((sumOfCharges/100) * requiredGSTpercentage).toFixed(2)
    }

    return parseFloat(calculatedAdditionalCharge)
  }

  // mimimum GST charges on containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMC and (total sum of individual GST of each item) that is gstSum
  const finalGSTamount = (containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMC,gstSum)=>
  {
    const cpCharges = containerPackingCharges === '' ? 0 : parseFloat(containerPackingCharges);
    const luCharges = loadingUnloadingCharges === '' ? 0 : parseFloat(loadingUnloadingCharges);
    const oCharges = otherCharges === '' ? 0 : parseFloat(otherCharges);

   let calculatedGST =  parseFloat(calculateGSTforAdditionalCharges(cpCharges,luCharges,oCharges,totalAPMC)+parseFloat(gstSum))
   return parseFloat(calculatedGST.toFixed(2))
  }

  // Gross Amount + final GST calculation - TDS
  const getPO =(grossAmount,TDS,gstSum,isCalculated) =>{
    
    let poCalculation = 0
    if (isCalculated[0]==="non_calculated")
    {
      //totalAPMC is equal to isCalculated[1]
      poCalculation=parseFloat(finalGSTamount(containerPackingCharges,loadingUnloadingCharges,otherCharges,isCalculated[1],gstSum)+parseFloat(grossAmount)-parseFloat(TDS)).toFixed(2)
     
    }
    else{
      poCalculation = parseFloat((parseFloat(isCalculated)+parseFloat(grossAmount))-parseFloat(TDS)).toFixed(2)
    }

    setFinalPO(poCalculation)
    return poCalculation
  }
 

  return (
    <>
      <NavBar />
      
      <Form 
      onWheel={(event) => event.target.blur()}
      noValidate validated={validated} onSubmit={PushPurchaseDetails}>
        <Navbar
          className="bg-light mr-auto justify-content-between"
          style={{ paddingTop: 100 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Badge variant="dark">Create Purchase Details </Badge>
          </div>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              openListPurchase();
            }}
          >
            List Purchase Details
          </Button>
        </Navbar>
        {articleInfo == "" ? (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ display: "flex", justifyContent: "center" }}
            timeout={10000} //20 secs
          />
        ) : (
          <>

{/* <Table size="sm" responsive>
            <tbody>
              <tr>
                <td>Purchase Date</td>
                <td> */}
                <SimpleGrid cols={1} spacing={10}>
                <Col span={12} style={{zIndex: 12}}> 
                <Form.Label>Purchase Date *</Form.Label>
                <DatePicker
                    ref={datepickerRef}
                    required
                    selected={dateToModify}
                    onChange={(val) => {
                      setDateToModify(val);
                      setPurchasedDate(
                        `${
                          val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );
                      // console.log("DEBUG check this", val);
                      fetchPoList(val);
                    }}
                    minDate={new Date("2017/01/01")}
                    maxDate={new Date()}
                    // minDate={new Date()}
                    // maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </Col>
                </SimpleGrid>
                <br />
                {/* <SimpleGrid cols={1} spacing={10} >
                  <Col span={12} style={{zIndex: 12}}>
                    <Form.Group>
                    <table style={{ width: '100%' }}>
                      <tbody>
                      <tr>
                        <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                        <input
                          style={{ marginLeft: "10px", display:"flex" }}
                          type="checkbox"
                          id="existing_po_checkbox"
                          name="existing_po"
                          checked={showExistingPoChecked}
                          onChange={(event) => {
                            setShowExistingPoChecked(event.target.checked);
                            setExistingPoSelection(null);
                            setIsSupplierSelectionDisabled(false);
                            setSupplierSelection(null);
                            setSupplier("");
                          }}
                        />
                          <label style={{ marginLeft: "10px", display:"flex", marginBottom: 0 }}> {" "}Select an Existing PO Number </label>
                          </div>
                          {showExistingPoChecked ? (
                            <Select
                              style={{ width: "100%"}}
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={existingPoNumbersToDisp.length === 0}
                              isClearable={true}
                              isSearchable={true}
                              name="existing_po_select"
                              options={existingPoNumbersToDisp}
                              value={existingPoSelection}
                              onChange={(val) => {
                                if (val) {
                                  setExistingPoSelection(val);
                                  if (
                                    exisitingPoNumbersAndSuppliers[val.value]
                                      .supplier_name &&
                                    exisitingPoNumbersAndSuppliers[val.value]
                                      .supplier_name !== ""
                                  ) {
                                    const supplier_name =
                                      exisitingPoNumbersAndSuppliers[val.value]
                                        .supplier_name;
                                    setSupplierSelection({
                                      value: supplier_name,
                                      label: supplier_name,
                                    });
                                    setSupplier(supplier_name);
                                    setIsSupplierSelectionDisabled(true);

                                    if (
                                      exisitingPoNumbersAndSuppliers[val.value]
                                        .invoice_number
                                    ) {
                                      setInvoiceNumber(
                                        exisitingPoNumbersAndSuppliers[val.value]
                                          .invoice_number
                                      );
                                      setIsInvoiceFieldDisabled(true);
                                    } else {
                                      setInvoiceNumber("");
                                      setIsInvoiceFieldDisabled(false);
                                    }
                                  } else {
                                    setSupplierSelection(null);
                                    setSupplier("");
                                    setInvoiceNumber("");
                                    setIsSupplierSelectionDisabled(false);
                                    setIsInvoiceFieldDisabled(false);
                                  }
                                } else {
                                  setSupplierSelection(null);
                                  setSupplier("");
                                  setInvoiceNumber("");
                                  setExistingPoSelection({});
                                  setIsSupplierSelectionDisabled(false);
                                  setIsInvoiceFieldDisabled(false);
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        </tr>
                      </tbody>
                    </table>  
                    </Form.Group>
                </Col>
                </SimpleGrid> */}
                
                {/* </td>
              </tr>
              </tbody>
              </Table> */}
          {addedItems.map((addedItem, index) => (
                  <div key={index}>

                    <SimpleGrid cols={1} spacing={10} >
                      <Col span={12} style={{zIndex: 10}}>
                        <Form.Group>
                          {/* <InputGroup> */}
                            {/* <InputGroupAddon addonType="append"> */}
                            <table style={{ width: '100%' }}>
                            <tbody>
                              <tr><td><label style={{display:"flex"}}>Item Name * </label>
                              
                            {/* </InputGroupAddon> */}
                            {addedItem.articleCode == '' || addedItem.disableList == false ? (
                              // <Select
                              //   required
                              //   options={articleOptions}
                              //   value={addedItem.itemSelection}
                              //   onChange={(val) => {
                              //     const updatedItems = [...addedItems];
                              //     updatedItems[index] = {
                              //       ...addedItem,
                              //       itemSelection: val,
                              //       itemName: val.value,
                              //     };
                              //     setAddedItems(updatedItems);
                              //     setItemSelection(val);
                              //     setItemName(val.value);
                              //     let itemName = val.value;
                              //     let itemCode = null;
                              //     insertDomData(itemName, itemCode, updatedItems, index);
                              //   }}
                              //   style={{ width: "10px" }}
                              // />
                              <>
                              {/* <Input
                                value={addedItems[index].itemSelection ? addedItems[index].itemSelection.label : ''}
                                readOnly
                                required
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                              /> */}
                              {/* <InputGroupAddon addonType="append"> */}
                              {/* <ButtonDropdown isOpen={isOpenItemNameList[index]} toggle={()=>toggleDropdownItemName(index)} direction="left"> */}
                                {/* <DropdownToggle caret >
                                  Select
                                </DropdownToggle>
                                <DropdownMenu>
                                  {articleOptions && articleOptions.map((option) => (
                                    <DropdownItem key={option.value} onClick={() => handleDropdownItemClickItemName(option,addedItems,index,addedItem)}>
                                      {option.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu> */}
                                
                                {/* <td> */}
                                <Select
                                required
                                options={articleOptions}
                                value={addedItem.itemSelection}
                                // onChange={(val) => {
                                //   const updatedItems = [...addedItems];
                                //   updatedItems[index] = {
                                //     ...addedItem,
                                //     itemSelection: val,
                                //     itemName: val.value,
                                //   };
                                //   setAddedItems(updatedItems);
                                //   setItemSelection(val);
                                //   setItemName(val.value);
                                //   let itemName = val.value;
                                //   let itemCode = null;
                                //   insertDomData(itemName, itemCode, updatedItems, index);
                                // }}
                                onChange={(val) => {

                                   const updatedItems = [...addedItems];
                                  updatedItems[index].bagPurchased=0
                                  updatedItems[index].totalWeight=0
                                  setAddedItems(updatedItems)

                                  handleDropdownItemClickItemName(val,addedItems,index,addedItem)

                                  setBagPurchased(0)
                                  setTotalWeight(0)
                                 

                                  setContainerPackingCharges(0)
                                  setLoadingUnloadingCharges(0)
                                  setOtherCharges(0)
                                  setGrossAmount(0)
                                  setTotalAPMC(0)
                                  setTotalAmount(0)
                                  setGstSum(0)
                                  setfinalGST(0)
                                  setTDS(0)
                                  setFinalPO(0)
                                }}
                                style={{ width: "100%"}}
                              />
                              
                              
                              {/* </ButtonDropdown> */}
                            {/* </InputGroupAddon> */}
                            </>
                            ) : (
                              <Input value={addedItem.itemName} readOnly />
                              // <Form.Control value={addedItem.itemName} readOnly />
                            )}
                            {/* </InputGroupAddon> */}
                              </td> 
                              </tr>
                            </tbody>
                            </table>
                          {/* </InputGroup> */}
                        </Form.Group>
                      </Col>
                    </SimpleGrid>
                    
                    <SimpleGrid cols={3} spacing={5}>
                      <Col span={5}>
                          <Form.Group> 
                            <InputGroup>
                            <InputGroupAddon addonType="prepend">Source *</InputGroupAddon>
                                    <Input
                                      value={addedItems[index].Source?.toUpperCase() ? addedItems[index].Source.toUpperCase() : ""}
                                      
                                    />
                                    <InputGroupAddon addonType="append">
                                      <ButtonDropdown isOpen={isOpenSourceList[index]} toggle={()=>toggleDropdownSource(index)} style={{ zIndex: 1 }}>
                                        <DropdownToggle caret>
                                          Select
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('MILL',addedItems,index,addedItem)}>MILL</DropdownItem>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('LOCAL PICK',addedItems,index,addedItem)}>LOCAL PICK</DropdownItem>
                                          <DropdownItem onClick={() => handleDropdownItemClickSource('DC DELIVERED',addedItems,index,addedItem)}>DC DELIVERED</DropdownItem>
                                        </DropdownMenu>
                                      </ButtonDropdown>
                                    </InputGroupAddon>
                            </InputGroup>
                            <FormFeedback>*Required</FormFeedback>
                          </Form.Group>
                      </Col>
                      <Col span ={5}>
                      {addedItem.Source === 'MILL' ? (
                            <>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">Transport *</InputGroupAddon>
                          <Input value={addedItems[index].Transport?.toUpperCase()  ? addedItems[index].Transport.toUpperCase() : ""} readOnly />
                          <InputGroupAddon addonType="append">
                            <ButtonDropdown isOpen={isOpenTransportList[index]} toggle={()=>toggleDropdownTransport(index)} style={{ zIndex: 1 }}>
                              <DropdownToggle caret>  
                                Select
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => handleDropdownItemClickTransport('INCLUDED',addedItems,index,addedItem)}>INCLUDED</DropdownItem>
                                <DropdownItem onClick={() => handleDropdownItemClickTransport('EXCLUDED',addedItems,index,addedItem)}>EXCLUDED</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </InputGroupAddon>
                        </InputGroup>
                        </> ) : null}

                      </Col>
                      <Col span = {5}>
                        {addedItem.Source === 'MILL' && addedItem.Transport.toLowerCase() === 'excluded' ? (
                          // <tr><td>Transport Charges</td>
                          // <td><Form.Control value={addedItem.TransCharges} onChange={(e) => {
                          //                     const updatedItems = [...addedItems];
                          //                     updatedItems[index] = {
                          //                       ...addedItem,
                          //                       TransCharges:e.target.value
                          //                     };
                          //                     setAddedItems(updatedItems);
                          //                     setTransCharges(e.target.value)}
                          //                   }/></td></tr>

                          <Form.Group> 
                          <InputGroup>
                              <InputGroupAddon addonType="prepend"> Transport Charges </InputGroupAddon>
                              <Input
                                value={addedItem.TransCharges} onChange={(e) => {
                                      const updatedItems = [...addedItems];
                                      updatedItems[index] = {
                                        ...addedItem,
                                        TransCharges:e.target.value
                                      };
                                      setAddedItems(updatedItems);
                                      setTransCharges(e.target.value)}
                                    }
                              />
                          </InputGroup>     
                          </Form.Group>) : null}
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                        
                        <Col span = {5}>
                        {addedItem.Source === 'MILL' ? (
                          // <tr><td>Cash Discount</td>
                          // <td><Form.Control value={addedItem.CashDisc} onChange={(e) => {
                          //                                 const updatedItems = [...addedItems];
                          //                                 updatedItems[index] = {
                          //                                   ...addedItem,
                          //                                   CashDisc:e.target.value
                          //                                 };
                          //                                 setAddedItems(updatedItems);
                          //                                 setCashDisc(e.target.value)}
                          //                                 }/></td></tr>

                          <Form.Group> 
                          <InputGroup>
                              <InputGroupAddon addonType="prepend"> Cash Discount </InputGroupAddon>
                              <Input
                                value={addedItem.CashDisc} onChange={(e) => {
                                      const updatedItems = [...addedItems];
                                      updatedItems[index] = {
                                        ...addedItem,
                                        CashDisc:e.target.value
                                      };
                                      setAddedItems(updatedItems);
                                      setCashDisc(e.target.value)}
                                      }
                              />
                          </InputGroup>     
                          </Form.Group>):null}
                        </Col>
                        <Col span = {5}>
                        <Form.Group> 
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                            Category
                            </InputGroupAddon>
                            <Input
                              value={ addedItem.category}
                              ref={categoryField} 
                              readOnly
                            />
                        </InputGroup>     
                        </Form.Group> 
                          {/* <Form.Label>Category</Form.Label>
                          <Form.Control value={addedItem.category} ref={categoryField} readOnly /> */}
                        </Col>
                        <Col span= {5}>
                        <Form.Group> 
                        <InputGroup>
                        <InputGroupAddon addonType="prepend"> Article Code </InputGroupAddon>
                        {addedItem.itemName == "" || addedItem.disableList ? (
                                <Input
                                  ref={itemNoField}
                                  value={addedItem.articleCode}
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      articleCode: e.target.value,
                                      // itemName: val.value,
                                    };
                                    setAddedItems(updatedItems);
                                    setArticleCode(e.target.value);
                                    let itemCode = e.target.value;
                                    let itemName = null;
                                    insertDomData(itemName, itemCode,updatedItems, index);
                                  }}
                                />
                              ) : (
                                <Input
                                  ref={itemNoField}
                                  value={addedItem.articleCode}
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    updatedItems[index] = {
                                      ...addedItem,
                                      articleCode: e.target.value,
                                      // itemName: val.value,
                                    };
                                    setAddedItems(updatedItems);
                                    setArticleCode(e.target.value);
                                    let itemCode = e.target.value;
                                    let itemName = null;
                                    insertDomData(itemName, itemCode,updatedItems,index);
                                  }}
                                  readOnly
                                />
                              )}
                        </InputGroup> 
                        </Form.Group> 
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                          <Col >
                            {/* <Form.Label>HSN Code</Form.Label>
                              <Form.Control  value = {addedItem.hsn} readOnly /> */}
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                HSN Code
                                </InputGroupAddon>
                                <Input
                                  value={ addedItem.hsn}
                                  readOnly
                                />
                            </InputGroup>     
                            </Form.Group>   
                          </Col>
                          <Col >
                          <Form.Group>
                          <InputGroup>
                          <InputGroupAddon addonType="prepend"> Outer Type</InputGroupAddon>
                                  <Input
                                    value={addedItems[index].outerType ? addedItems[index].outerType :''}
                                    readOnly
                                  />
                                  {/* <InputGroupAddon addonType="append">
                                    <ButtonDropdown isOpen={isOpenList[index]} toggle={() => toggleDropdown(index)} style={{ zIndex: 1 }}>
                                      <DropdownToggle caret>
                                        {/* You can customize the button appearance here */}
                                        {/* Select */}
                                      {/* </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => handleDropdownItemClick('SMALL PACK',addedItems,index,addedItem)}>SMALL PACK</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('GONI',addedItems,index,addedItem)}>GONI</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('BAG OF PACKS',addedItems,index,addedItem)}>BAG OF PACKS</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('HIGH VALUE OF LOOSE',addedItems,index,addedItem)}>HIGH VALUE OF LOOSE</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownItemClick('FMCG',addedItems,index,addedItem)}>FMCG</DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </InputGroupAddon>  */}
                          </InputGroup>
                          </Form.Group>
                          </Col>
                          <Col >
                          {/* <Form.Label>Outer Size(Kg/Pc)</Form.Label>
                              <Form.Control
                                readOnly
                                value={addedItem.outerSize ? addedItem.outerSize + " " + addedItem.uomValue : ""}
                              />
                              <Form.Control.Feedback type="invalid">
                                *Required
                              </Form.Control.Feedback> */}

                              <Form.Group> 
                              <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                  Outer Size(Kg/Pc)
                                  </InputGroupAddon>
                                  <Input
                                    value={addedItem.outerSize ? addedItem.outerSize + " " + addedItem.uomValue : ""}
                                    readOnly
                                  />
                                  <FormFeedback> *Required </FormFeedback>
                              </InputGroup>     
                              </Form.Group>
                          </Col>

                    </SimpleGrid>

                    <SimpleGrid cols = {3} spacing={5}>
                    <Col>
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Base Price
                                </InputGroupAddon>
                                <Input type = "number"
                                  value={addedItem.basePrice ? addedItem.basePrice : ""}
                                  readOnly
                                />
                            </InputGroup>     
                            </Form.Group>
                      </Col>
                      {/* Changes for purchasedPrice calculation*/}
                          {/* 
                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];

                                    const newPurchasedPrice = e.target.value;

                                    updatedItems[index] = {
                                      ...addedItem,
                                      purchasedPrice: Number(e.target.value),
                                    };


                                    let newTotalWeight = 0
                                    let newAmount= 0
                                    let newAPMCCharge = 0
                                    if(Number(addedItem.bagPurchased)>0){
                                      // directly mutating the addedItem in this
                                      newTotalWeight=addedItem.outerSize * addedItem.bagPurchased;
                                      newAmount = calculateItemLevelAmount({ ...updatedItems, bagPurchased: addedItem.bagPurchased },"NoOfBagsPurchased");
                                      newAPMCCharge = calculateItemLevelAPMCCharge({ ...updatedItems, basePrice: newPurchasedPrice, totalWeight: newTotalWeight, amount:newAmount });
                                    }
                                    else{
                                      // directly mutating the addedItem in this
                                      newTotalWeight=Number(addedItem.totalWeight)
                                      newAmount = calculateItemLevelAmount({ ...updatedItems, bagPurchased: addedItem.bagPurchased },"");
                                      newAPMCCharge = calculateItemLevelAPMCCharge({ ...updatedItems, basePrice: newPurchasedPrice, totalWeight: newTotalWeight, amount:newAmount });
                                    }


                                    updatedItems[index] = {
                                      ...addedItem,
                                      PoAmt:'',
                                      amount: newAmount,
                                      apmcCharge: newAPMCCharge,
                                      gstCharge : calculatedItemLevelGSTCharge[1]  // calculatedItemLevelGSTCharge[0] is totalGSTAmount
                                    };


        
                                    // this also calculates sum of GST
                                    // mutating updatedItems in this so the updated .amount property is not present in updatedItem right here so passing it in the argument
                                    const calculatedItemLevelGSTCharge = calculateItemLevelGSTCharge(updatedItems[index], updatedItems, GstCharges, newAmount);

                                 
                                    // calculates total amount
                                    const calculatedTotalAMount= calculateTotalAmount(updatedItems)
                                    setTotalAmount(calculatedTotalAMount)
                                    // calculate total APMC
                                    const totalAPMCamount=calculateTotalAPMC(updatedItems)
                                    setTotalAPMC(totalAPMCamount)
                                    // calculates with initial value of other charges as 0 (cpCharges,luCharges,oCharges) 
                                    // only for the totalAPMC and sum of total GST with the minimum % found except 0
                                    const calculatedGSTamount=finalGSTamount(containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMCamount,calculatedItemLevelGSTCharge[0])
                                    setfinalGST(calculatedGSTamount)


                                    setAddedItems(updatedItems);
                                    setPurchasePrice(e.target.value)

                                    // Inside this function it will set the sate for setGrossAmount
                                    const calculatedGrossAmount = handleGrossAmount(containerPackingCharges, loadingUnloadingCharges, otherCharges, totalAPMCamount,calculatedTotalAMount )
                                    // Inside this function it will set the sate for setFinalPO
                                    getPO(calculatedGrossAmount,TDS,calculatedItemLevelGSTCharge[0],["non_calculated",totalAPMCamount])

                                    // setPoAmt("") 


                                  } 
                                */}
                      <Col>
                            <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Purchase Price
                                </InputGroupAddon>
                                <Input type = "number"
                                  required
                                  step={"any"}
                                  value={addedItem.purchasePrice}

                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];

                                    const newTotalWeight=addedItem.outerSize * addedItem.bagPurchased;
                                    const newAmount = calculateItemLevelAmount({ ...updatedItems, bagPurchased: addedItem.bagPurchased,outerSize:addedItem.outerSize, purchasePrice : Number(e.target.value)  },"NoOfBagsPurchased");
                                    const newAPMCCharge = calculateItemLevelAPMCCharge({ ...updatedItems, totalWeight: newTotalWeight, amount:newAmount });
                                    // addedItem.gstPercentage this will be made first at No. of Bags Purchased value then purchase price calculation is applicable
                                    const calculatedItemLevelGSTCharge = calculateItemLevelGSTCharge(updatedItems[index], updatedItems, (addedItem.gstPercentage || 0) , newAmount); //GstCharges

                                    updatedItems[index] = {
                                      ...addedItem,
                                      purchasePrice: e.target.value,

                                      amount: newAmount,
                                      apmcCharge: newAPMCCharge,
                                      gstCharge : calculatedItemLevelGSTCharge[1],

                                      PoAmt:''
                                    };

                                    // calculate total APMC
                                    const totalAPMCamount=calculateTotalAPMC(updatedItems)
                                    setTotalAPMC(totalAPMCamount)

                                    setAddedItems(updatedItems);
                                    setPurchasePrice(e.target.value)
                                    setPoAmt("")}}

                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>     
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Replacement Brand *
                                </InputGroupAddon>
                                <Input value={addedItem.replacementBrand ? addedItem.replacementBrand : ""}
                                  required
                                  onChange={(e) => {
                                  const updatedItems = [...addedItems];
                                  updatedItems[index] = {
                                    ...addedItem,
                                    replacementBrand: e.target.value
                                  };
                                  setAddedItems(updatedItems);
                                  setReplacementBrand(e.target.value);
                                  }}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>     
                              </Form.Group>
                        </Col>
                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                      <Col span={5}>
                        {/* <Form.Label>No Of Bags Purchased *</Form.Label>
                        <Form.Control
                              type="number"
                              value={addedItem.bagPurchased}
                              required
                              onChange={(e) => {
                                const updatedItems = [...addedItems];
                                updatedItems[index] = {
                                  ...addedItem,
                                  bagPurchased:e.target.value
                                };
                                setAddedItems(updatedItems);
                                setBagPurchased(e.target.value);
                                setTotalWeight("");

                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              *Required
                            </Form.Control.Feedback> */}

                      <Form.Group>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">No Of Bags Purchased *</InputGroupAddon>
                          <Input
                            type="number"
                            value={addedItem.bagPurchased}
                            required
                            onChange={(e) => {
                              const newBagPurchased = e.target.value;

                              // Validate input to prevent removing valid entries
                              if (newBagPurchased === "" || isNaN(Number(newBagPurchased))) {
                                setAddedItems((prev) =>
                                  prev.map((item, idx) =>
                                    idx === index ? { ...item, bagPurchased: "" } : item
                                  )
                                );
                                return;
                              }

                              const updatedItems = [...addedItems];
                              const newTotalWeight = addedItem.outerSize * newBagPurchased;

                              // Avoid direct mutation, update after all calculations
                              const newAmount = calculateItemLevelAmount(
                                { ...addedItem, bagPurchased: newBagPurchased },
                                "NoOfBagsPurchased"
                              );

                              const newAPMCCharge = calculateItemLevelAPMCCharge({
                                ...addedItem,
                                basePrice: addedItem.purchasePrice,
                                totalWeight: newTotalWeight,
                                amount: newAmount,
                              });

                              

                              if( !(index in selectedItemsGST_rates) )
                              {
                              const gstRatePromise = priceDetailsDB
                                .child(`${updatedItems[index].articleCode}`)
                                .once("value")
                                .then((snapshot) => {
                                  const prices = snapshot.val();
                                  return parseFloat(prices?.gst_per) || 0;
                                })
                                .catch((error) => {
                                  console.error("Error fetching data:", error);
                                  return 0.0;
                                });

                                gstRatePromise.then((gstRate) => {
                                  setSelectedItemsGST_rates((prevRates) => ({
                                    ...prevRates,
                                    [index]: gstRate
                                  }));
                                });

                                gstRatePromise.then((gstRate) => {
                                  const calculatedItemLevelGSTCharge = calculateItemLevelGSTCharge(
                                    updatedItems[index],
                                    updatedItems,
                                    gstRate,
                                    newAmount
                                  );
  
                                  updatedItems[index] = {
                                    ...addedItem,
                                    bagPurchased: newBagPurchased,
                                    totalWeight: newTotalWeight,
                                    amount: newAmount,
                                    apmcCharge: newAPMCCharge,
                                    gstCharge: calculatedItemLevelGSTCharge[1], // Use calculatedItemLevelGSTCharge[0] for total GST
                                  };
  
                                  // Update the calculated values in state
                                  const calculatedTotalAmount = calculateTotalAmount(updatedItems);
                                  const totalAPMCAmount = calculateTotalAPMC(updatedItems);
                                  const calculatedGSTAmount = finalGSTamount(
                                    containerPackingCharges,
                                    loadingUnloadingCharges,
                                    otherCharges,
                                    totalAPMCAmount,
                                    calculatedItemLevelGSTCharge[0]
                                  );
                                  const calculatedGrossAmount = handleGrossAmount(
                                    containerPackingCharges,
                                    loadingUnloadingCharges,
                                    otherCharges,
                                    totalAPMCAmount,
                                    calculatedTotalAmount
                                  );
  
                                  setAddedItems(updatedItems);
                                  setTotalAmount(calculatedTotalAmount);
                                  setTotalAPMC(totalAPMCAmount);
                                  setfinalGST(calculatedGSTAmount);
                                  getPO(
                                    calculatedGrossAmount,
                                    TDS,
                                    calculatedItemLevelGSTCharge[0],
                                    ["non_calculated", totalAPMCAmount]
                                  );
                                });

                              }
                              else{

                                const gstRate = selectedItemsGST_rates[index]
                                const calculatedItemLevelGSTCharge = calculateItemLevelGSTCharge(
                                  updatedItems[index],
                                  updatedItems,
                                  gstRate,
                                  newAmount
                                );

                                updatedItems[index] = {
                                  ...addedItem,
                                  bagPurchased: newBagPurchased,
                                  totalWeight: newTotalWeight,
                                  amount: newAmount,
                                  apmcCharge: newAPMCCharge,
                                  gstCharge: calculatedItemLevelGSTCharge[1], // Use calculatedItemLevelGSTCharge[0] for total GST
                                };

                                // Update the calculated values in state
                                const calculatedTotalAmount = calculateTotalAmount(updatedItems);
                                const totalAPMCAmount = calculateTotalAPMC(updatedItems);
                                const calculatedGSTAmount = finalGSTamount(
                                  containerPackingCharges,
                                  loadingUnloadingCharges,
                                  otherCharges,
                                  totalAPMCAmount,
                                  calculatedItemLevelGSTCharge[0]
                                );
                                const calculatedGrossAmount = handleGrossAmount(
                                  containerPackingCharges,
                                  loadingUnloadingCharges,
                                  otherCharges,
                                  totalAPMCAmount,
                                  calculatedTotalAmount
                                );

                                setAddedItems(updatedItems);
                                setTotalAmount(calculatedTotalAmount);
                                setTotalAPMC(totalAPMCAmount);
                                setfinalGST(calculatedGSTAmount);
                                getPO(
                                  calculatedGrossAmount,
                                  TDS,
                                  calculatedItemLevelGSTCharge[0],
                                  ["non_calculated", totalAPMCAmount]
                                );
                              }

                              
                            

                              setBagPurchased(newBagPurchased);
                              setTotalWeight(newTotalWeight);
                            }}
                          />
                          <FormFeedback>*Required</FormFeedback>
                        </InputGroup>
                      </Form.Group>
                      </Col>

                      <Col span={5}>

                        <Form.Group> 
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Total Weight Purchased *
                                </InputGroupAddon>
                                <Input
                                  disabled
                                  // Giving priority to the calculation according to the bagPurchased
                                  // if bagPurchased is not available then calculation is according to totalWeight

                                  value={
                                    addedItem.bagPurchased
                                      ? addedItem.bagPurchased * (addedItem.outerSize || 0)
                                      : addedItem.totalWeight
                                  }

                                  // value={
                                  //  addedItem.bagPurchased
                                  //     ? addedItem.bagPurchased * (addedItem.outerSize || 0)
                                  //     : addedItem.totalWeight
                                  // }


                                  onChange={(e) => {
                                    const updatedItems = [...addedItems];
                                    const newTotalWeight = e.target.value;
                                    const newBagPurchased = newTotalWeight / addedItem.outerSize;
                                     // directly mutating the addedItem in this
                                    const newAmount = calculateItemLevelAmount({ ...addedItem, totalWeight: newTotalWeight, bagPurchased: newBagPurchased },"directCalculation");
                                    const newAPMCCharge = calculateItemLevelAPMCCharge({ ...addedItem, basePrice: addedItem.purchasePrice, totalWeight: newTotalWeight, amount:newAmount });
                                    // this also calculates sum of GST
                                     // mutating updatedItems in this so the updated .amount property is not present in updatedItem right here so passing it in the argument
                                     const calculatedItemLevelGSTCharge = calculateItemLevelGSTCharge(updatedItems[index], updatedItems, GstCharges, newAmount);
                                  

                                    updatedItems[index] = {
                                      ...addedItem,
                                      totalWeight:e.target.value,
                                      amount: newAmount,
                                      apmcCharge: newAPMCCharge,
                                      gstCharge : calculatedItemLevelGSTCharge[1]  // calculatedItemLevelGSTCharge[0] is totalGSTAmount
                                    };

                                     // Note it is necessary to run the functions mentioned below after the above  updatedItems[index] 

                                     

                                      // calculates total amount
                                      const calculatedTotalAMount= calculateTotalAmount(updatedItems)
                                      setTotalAmount(calculatedTotalAMount)
                                      // calculate total APMC
                                      const totalAPMCamount=calculateTotalAPMC(updatedItems)
                                      setTotalAPMC(totalAPMCamount)
                                       // calculates with initial value of other charges as 0 (cpCharges,luCharges,oCharges) 
                                       // only for the totalAPMC and sum of total GST with the minimum gst % found except 0
                                      const calculatedGSTamount=finalGSTamount(containerPackingCharges,loadingUnloadingCharges,otherCharges,totalAPMCamount,calculatedItemLevelGSTCharge[0])
                                      setfinalGST(calculatedGSTamount)


                                    setAddedItems(updatedItems);
                                    setTotalWeight(e.target.value);
                                    setPoAmt(Number(e.target.value) *  Number(purchasePrice))

                                     // Inside this function it will set the sate for setGrossAmount
                                     const calculatedGrossAmount = handleGrossAmount(containerPackingCharges, loadingUnloadingCharges, otherCharges, totalAPMCamount,calculatedTotalAMount )
                                     // Inside this function it will set the sate for setFinalPO
                                      getPO(calculatedGrossAmount,TDS,calculatedItemLevelGSTCharge[0],["non_calculated",totalAPMCamount])
                                  }}
                                />
                                <FormFeedback> *Required </FormFeedback>
                            </InputGroup>
                            </Form.Group>
                      </Col>
                      {/* If bagPurchased or totalWeight becomes 0 then there is nothing to calculate for */}
                      <Col>
                            <Form.Group>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                Amount
                                </InputGroupAddon>
                                <Input type = "number"
                                    value = {addedItem.bagPurchased === 0 || addedItem.totalWeight === 0 ? 0 : (addedItem.amount || 0)}
                                  readOnly
                                />
                            </InputGroup>
                            </Form.Group>
                      </Col>

                    </SimpleGrid>

                    <SimpleGrid cols={3} spacing={5}>
                    <Col>
                      <Form.Group>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            APMC Charge
                          </InputGroupAddon>
                          <Input
                            type="number"
                            value= {addedItem.bagPurchased === 0 || addedItem.totalWeight === 0 ? 0 : (addedItem.apmcCharge || 0)}
                            readOnly
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>

                <Col>
                <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        GST Percentage
                      </InputGroupAddon>
                      <Input
                        type="number"
                        value={addedItem.bagPurchased === 0 || addedItem.totalWeight === 0 ? 0 : (addedItem.gstPercentage || 0)}
                        readOnly
                      />
                    </InputGroup>
                    </Col>

                    <Col>
                  <Form.Group>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        GST Charge
                      </InputGroupAddon>
                      <Input
                        type="number"
                        value={addedItem.bagPurchased === 0 || addedItem.totalWeight === 0 ? 0 : (addedItem.gstCharge || 0)}
                        readOnly
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>


                    </SimpleGrid>
                      <br />
                      <br />
                  </div>
          ))}
          
          <Button variant="primary" onClick={handleAddItem}>
            Add Item
          </Button>
          <br />
          <br />
          
          <Table size="sm" responsive>
          <tbody>                    
          <tr>
                <td>Invoice Date</td>
                <td>
                  <DatePicker
                    required
                    selected={dateChange}
                    minDate={dateToModify}
                    // selected should be greater than or equal to po date
                    onChange={(val) => {
                      setdateChange(val);
                      setinvoiceDate(
                        `${
                            val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );                    }}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </td>
              </tr>
              <tr>
                <td>Invoice Number</td>
                <td>
                  <Form.Control
                    step={"any"}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    value={invoiceNumber}
                  />
                </td>
              </tr>
              <tr>
                <td>Payment Terms (in Days) *</td>
                <td>
                  <Form.Control required type="number"  value={Payment_Terms} onChange={(e) => {
                    setPayment_Terms(e.target.value)
                  }}/>
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Delivery Period (in Days) *</td>
                <td>
                  <Form.Control type="number" required value={Delivery} onChange = {(e) =>{setDelivery(e.target.value)} } />
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Supplier Name *</td>
                <td>
                  {/* <Creatable required options={suppliersList} ref={supplierRef} value={supplierSelection} onChange={(val) => { setSupplierSelection(val); setSupplier(val.value) }} style={{ width: "100px" }} /> */}
                  <Select
                    required
                    options={suppliersList}
                    ref={supplierRef}
                    value={supplierSelection}
                    onChange={(val) => {

                      let errorFlag = false

                      const fssaiRegex = /^([1-2]\d{13})$/;
                      // const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1Z][0-9A-Z]{1}$/
                      // /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/
                      const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1-9][0-9A-Z]{2}$/


                        // for expenses type fssai check is excluded
                        if (val?.supplierType?.toLowerCase() !== "expenses") {

                          if(!fssaiRegex.test(val.fssai))
                          {
                          errorFlag=true
                          setError({supplierError:"Please Check FSSAI format for the selected supplier"})
                          }

                        } 
                          
                          // If GSTIN is present only then validate its format
                          if (val.gstin) {
                            
                            if (!gstRegex.test(val.gstin)) {

                             errorFlag=true
                             setError({ supplierError: "Invalid GST number format" });
                            }
                          }

                          setSupplierSelection(val);
                          setSupplier(val.value);
                          setSupplierID(val.key);
                          setSupplierEmail(val.email);
                          setAddress(val.address);
                          setStreet(val.street)
                          setCity(val.city)
                          setPin(val.pin)
                          setState(val.state)
                          setPhone(val.phone);
                          setGstin(val.gstin);
                          setFssai(val.fssai)
                          setSupplierGroup(val.supplierGroup)
                        

                          if(errorFlag)
                          {
                          setSupplierSelection("");
                            setSupplier("");
                            setSupplierID("");
                            setSupplierEmail("");
                            setAddress("");
                            setStreet("")
                            setCity("")
                            setPin("")
                            setState("")
                            setPhone("");
                            setGstin("");
                            setFssai("")
                            setSupplierGroup("")
                            errorFlag=false
                          }
                        
                    }}
                    style={{ width: "100px" }}
                    isDisabled={isSupplierSelectionDisabled}
                  />

                  {supplierError && (
              <div style={{ color: 'red', fontSize: '0.8rem', marginTop: '4px' }}>
                {supplierError}
              </div>
            )}
                </td>
                <td>
                  {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      window.open("#/addsupplier");
                    }}
                  >
                    Add Supplier
                  </Button>}
                </td>
              </tr>
              {/* Supplier ID from Supplier DB */}
              <tr>
                <td>Supplier ID </td>
                <td>
                  <Form.Control value = {SupplierID} readOnly />
                </td>
              </tr>
              {/* Supplier Email from Supplier DB */}
              <tr>
                <td>Supplier Email</td>
                <td>
                  <Form.Control value = {SupplierEmail}   onChange = {(e) => setSupplierEmail(e.target.value) }/>
                </td>
              </tr>
              {/* Other Charges */}
       
          {/* <tr>
          <td>Container/Packing Charges</td>
          <td>
            <Form.Control
              value={containerPackingCharges}
              onChange={(e)=>{
                const grossAmount = handleContainerPackingChargesChange(e)
                const calculatedGST = finalGSTamount(e.target.value,loadingUnloadingCharges,otherCharges,totalAPMC,gstSum)
                setfinalGST(calculatedGST)
                getPO(grossAmount,TDS,gstSum,calculatedGST)
              }}
              type = "number"
            />
          </td>
        </tr> */}
        {/* <tr>
          <td>Loading &amp; Unloading Charges</td>
          <td>
            <Form.Control
              value={loadingUnloadingCharges}
              onChange={(e)=>{
                const grossAmount = handleLoadingUnloadingChargesChange(e)
                const calculatedGST = finalGSTamount(containerPackingCharges,e.target.value,otherCharges,totalAPMC,gstSum)
                setfinalGST(calculatedGST)
                getPO(grossAmount,TDS,gstSum,calculatedGST)
              }}
              type = "number"
            />
          </td>
        </tr> */}
        {/* <tr>
          <td>Other Charges</td>
          <td>
            <Form.Control
              value={otherCharges}
              onChange={(e)=>{
                const grossAmount = handleOtherChargesChange(e)
                const calculatedGST = finalGSTamount(containerPackingCharges,loadingUnloadingCharges,e.target.value,totalAPMC,gstSum)
                setfinalGST(calculatedGST)
                getPO(grossAmount,TDS,gstSum,calculatedGST)
              }
              }
              type = "number"
            />
          </td>
        </tr> */}
        <tr>
          <td>Total APMC Charges</td>
          <td>
            <Form.Control
             value ={totalAPMC} 
             readOnly />
          </td>
        </tr>
        {/* <tr>
          <td>Total Amount </td>
          <td>
            <Form.Control 
            value ={totalAmount} 
            readOnly />
          </td>
        </tr> */}
        {/* <tr>
          <td>Gross Amount </td>
          <td>
                <Form.Control
                 // type="number"
                  value={grossAmount}
                  readOnly
                />
              </td>
        </tr> */}

        {/* <tr>
                <td>GST Charges</td>
                <td>
                  <Form.Control
                  value ={parseFloat(finalGST).toFixed(2)} readOnly />
                </td>
          </tr> */}
            {/* {(supplierGroup.trim().toUpperCase()==="DANA" || supplierGroup.trim().toUpperCase()==="MASALA") && // considered as local suppliers
            <>
        <tr>
                <td>SGST </td>
                <td>
                  <Form.Control
                  value ={parseFloat(finalGST/2).toFixed(2)} readOnly />
                </td>
          </tr> 
          <tr>
                <td>CGST </td>
                <td>
                  <Form.Control
                  value ={parseFloat(finalGST/2).toFixed(2)} readOnly />
                </td>
          </tr>
               </>
              } */}


      {/* { supplierGroup.trim().toUpperCase()==="OUTSTATION" &&   // considered as Outstation suppliers
                <tr>
                      <td>IGST </td>
                      <td>
                        <Form.Control
                        value ={finalGST} readOnly />
                      </td>
                </tr>
      } */}
      
        {/* <tr>
          <td>TDS</td>
          <td>
            <Form.Control
              value={TDS}
              onChange={(e)=>{
                const TDS = handleTDS(e)
                getPO(grossAmount,TDS,gstSum,["non_calculated",totalAPMC])
              }}
              type = "number"
            />
          </td>
        </tr> */}
          
        
        {/* <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control
                  value ={finalPO} readOnly />
                </td>
          </tr> */}
        
      {/* Other Charges */}
              {/* <tr>
                <td>PO Amount </td>
                <td>
                  <Form.Control value ={PoAmt ? PoAmt.toFixed(2) : Number(purchasePrice * (totalWeight ? totalWeight : outerSize * bagPurchased)).toFixed(2)} readOnly />
                </td>
              </tr> */}
              </tbody>
          </Table>


          <center>
              <Button variant="success" type="submit" disabled={buttonStatus}>
                Add Purchase Details{" "}
              </Button>
            </center>
          </>
        )}
      </Form>
    </>
  );
}

export default Add_Purchase;
