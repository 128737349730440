import React, { useState, useMemo, useEffect , useRef} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";
import firebase from "../../service/firebaseConfig";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
import axios from "axios";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import moment from "moment";
import { CSVLink } from "react-csv";


// let DELIVERY_BASE_URL = "http://localhost:3000"
console.log(DELIVERY_BASE_URL);
var today = new Date();
var d = today.getDate();
var y = today.getFullYear();
var m = (today.getMonth() + 1).padLeft();
var today_moment = moment(today)


function SampleEntry() {
  let csv_data = useRef("")
  const [show1, setShow1] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [TableDataCsv, setTableDataCsv] = useState([]);
  const userCity = sessionStorage.getItem("Logged User City") || "";
  const userType = sessionStorage.getItem("Logged User Type");

  const [PayloadObj, setPayloadObj] = useState({
    sample_entry_date: `${y}-${m}-${d}`,
    created_by: sessionStorage.getItem("Logged User Email Id"),
  });

  const [TableDate, setTableDate] = useState({
    start_date: `${y}-${m}-${d}`,
    end_date: `${y}-${m}-${d}`,
  });
  const [ItemsList, setItemsList] = useState([]);
  const [ItemsObj, setItemsObj] = useState([]);

  const [UpdatePayloadObj, setUpdatePayloadObj] = useState({});

  const handleClose1 = () => {
    setShow1(false);
    setPayloadObj({});
  };
  const handleShow1 = () => {
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleShow2 = (prop) => {
    console.log(prop);
    setUpdatePayloadObj(prop);
    setShow2(true);
  };


  function createCsv(data,columns) {

    let final_csv = []
    for (let row of data){
  
      let one_row = []
      for (let col of columns){
  
        one_row.push(row[col.accessor])
      }
      final_csv.push(one_row)
    }
  
    let one_row = []
    for (let col of columns){
      console.log(col);
      one_row.push(col.Header
        )
    }
    final_csv.unshift(one_row)
    // setCsvData(final_csv)
    csv_data.current = final_csv;
  
  
  }

  useEffect(() => {
    const fetchProducts = async () => {
      const paths = ['Product_Master_New/A', 'Product_Master_New/F', 'Product_Master_New/FD'];
      let itm_list = [];
      let itm_obj = {};

      for (const path of paths) {
        const snapshot = await firebase.database().ref(path).once("value");
        if (snapshot.exists()) {
          const val = snapshot.val();
          for (let itm in val) {
            if ((val[itm].active_status === 'Y' || val[itm].buying_flag == 'Y' ) && !itm_obj[itm]) { // Check for duplicates
              itm_list.push({
                value: itm,
                label: `${itm} ${val[itm].item_description1} - ${val[itm].brand} - ${val[itm].weight}`,
              });
              itm_obj[itm] = val[itm]; // Store the item in the object to prevent duplicates
            }
          }
        }
      }

      setItemsList(itm_list);
      setItemsObj(itm_obj);
    };

    fetchProducts();

    return () => {};
  }, []);

  useEffect(() => {

    axios
      .get(
        `${DELIVERY_BASE_URL}/api/superzop/sampleentry/ListingSampleEntry?start_date=${TableDate.start_date}&end_date=${TableDate.end_date}`
      )
      .then((res) => {
        const filteredTableData = res.data.filter(
          itm => userType === 'admin' || itm.warehouse === userCity
        );
        
        setTableData(filteredTableData);

        let list = []
        for(let itm of res.data ){

          if (userType === 'admin' || itm.warehouse === userCity){  
          list.push({
            
            base_price: itm.base_price,
            category: itm.category,
            // createdAt: itm.createdAt,
            created_at:itm.created_at,
            created_by: itm.created_by,
            id: itm.id,
            item_code: itm.item_code,
            item_name: itm.item_name,
            outer_size: itm.outer_size,
            Outer_size_and_umo : `${itm.outer_size} ${itm.uom}`,
            sample_entry_date: moment(itm.sample_entry_date).format("DD-MMM"),
            sample_total_weight: itm.sample_total_weight,
            sample_unit:itm.sample_unit,
            total_cost: itm.total_cost,
            warehouse:itm.warehouse,
            uom: itm.uom
          })
        }
        }

        setTableDataCsv(list);


      })
      .catch((err) => {
        console.log(err);
      });
  }, [TableDate]);

  function createSampleEntry() {
    axios
      .post(
        `${DELIVERY_BASE_URL}/api/superzop/sampleentry/CreateSampleEntry`,
        PayloadObj
      )
      .then((res) => {
        // console.log(res.data);
        toast("Success full");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast("Error");
      });
  }

  function updateSampleEntry() {
    axios
      .put(
        `${DELIVERY_BASE_URL}/api/superzop/sampleentry/updateSampleEntry`,
        UpdatePayloadObj
      )
      .then((res) => {
        console.log(res.data);
        toast("Success full update");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast("Error");
      });
  }

  function Table1() {
    const data = useMemo(() => TableData, []);

 

    const columns = useMemo(
      () => [

        {
          Header: "Date",
          accessor: "sample_entry_date",
          Cell: ({ row }) => {
            return <p>  {moment(row.original.sample_entry_date).format("DD-MMM")}  </p>;
          },
        },
        {
          Header: "Item code",
          accessor: "item_code",
        },
        {
          Header: "Item name",
          accessor: "item_name",
        },
        {
          Header: "Category",
          accessor: "category",
        },

        {
          Header: "Outer size and Uom",
          accessor: "Outer_size_and_umo",
          Cell: ({ row }) => {
              return (<p> {row.original.outer_size} {row.original.uom} </p>)
          },
        },
        {
          Header: "Sample Weight (KG)",
          accessor: "sample_total_weight",
        },
        {
          Header: "Sample unit",
          accessor: "sample_unit",
        },
        {
          Header: "Base price",
          accessor: "base_price",
          Filter: SelectColumnFilter,
        },
     
        {
          Header: "Total cost",
          accessor: "total_cost",
        },
        {
          Header: "Warehouse",
          accessor: "warehouse",
        },
        {
          Header: "Edit",
          Cell: ({ row }) => {
            if (row.original.created_at.slice(0, 10) == today_moment.format("YYYY-MM-DD") ) {
              return (
                <Button onClick={() => handleShow2(row.original)}>
                  {" "}
                  Edit{" "}
                </Button>
              );
            } else {
              return <Button variant="secondary"> Edit </Button>;
            }
          },
        },
      ],
      []
    );


    const columns_csv = useMemo(
      () => [

        {
          Header: "Date",
          accessor: "sample_entry_date",
        },
        {
          Header: "Item code",
          accessor: "item_code",
        },
        {
          Header: "Item name",
          accessor: "item_name",
        },
        {
          Header: "Category",
          accessor: "category",
        },
        {
          Header: "Outer size and Uom",
          accessor: "Outer_size_and_umo",
        },
        {
          Header: "Sample Weight (KG)",
          accessor: "sample_total_weight",
        },
        {
          Header: "Sample unit",
          accessor: "sample_unit",
        },
        {
          Header: "Base price",
          accessor: "base_price",
 
        },
     
        {
          Header: "Total cost",
          accessor: "total_cost",
        },
        {
          Header: "Warehouse",
          accessor: "warehouse",
        },
    
      ],
      []
    );


    createCsv(TableDataCsv,columns_csv)

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <Form.Group>
          <br></br>
          <Form.Control
            placeholder="search..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </Form.Group>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>

          <Col>
            <Col>Start date</Col>
            <Col>
              {" "}
              <Form.Group>
                <Form.Control
                  type="date"
                  value={TableDate?.start_date}
                  onChange={(e) =>
                    setTableDate({
                      ...TableDate,
                      start_date: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Col>

          <Col>
            <Col>End date</Col>
            <Col>
              {" "}
              <Form.Group>
                <Form.Control
                  type="date"
                  value={TableDate?.end_date}
                  onChange={(e) =>
                    setTableDate({
                      ...TableDate,
                      end_date: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Col>

          <Col>
            <br></br>
            <Button variant="primary" onClick={handleShow1}>
              Add Sample Entry
            </Button>
          </Col>

          <Col>
          <br></br>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"Sample_Entry.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>{" "} 
          </Col>

          <Col></Col>
          <Col></Col>
        </Row>
        <br />

        <Table striped {...getTableProps()} style={{ width: "100%" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 1px ",
                      // background: "aliceblue",
                      // color: "black",
                      // fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={
                          {
                            // padding: "10px",
                            // border: "solid 1px gray",
                            // background: "papayawhip",
                          }
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  function handelSelect(e) {
    setPayloadObj({
      ...PayloadObj,
      item_code: e.value,
      category: ItemsObj[e.value].category,
      item_name: ItemsObj[e.value].item_description1 +" - "+ ItemsObj[e.value].brand +"  "+ ItemsObj[e.value].weight ,
      uom: ItemsObj[e.value].uom,
      outer_size: ItemsObj[e.value].outer_size,
      base_price : ItemsObj[e.value].base_price,
      sample_unit: 0,
      total_cost: 0,
      warehouse:userCity
    });
  }

  function handelSelectUpdate(e) {
    setUpdatePayloadObj({
      ...PayloadObj,
      item_code: e.value,
      category: ItemsObj[e.value].category,
      item_name: ItemsObj[e.value].item_description1,
      uom: ItemsObj[e.value].uom,
      outer_size: ItemsObj[e.value].outer_size,
      sample_unit: 0,
      total_cost: 0,
    });
  }

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2>Sample Entry </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Add Sample Entry </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label> Item code </Form.Label>
              <Select
                options={ItemsList}
                onChange={(e) => {
                  handelSelect(e);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control disabled type="text" value={PayloadObj.category} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Form.Control disabled type="text" value={moment(`${y}-${m}-${d}`).format("DD-MMM")}  />
            </Form.Group>

            <Form.Group>
              <Form.Label>Item name</Form.Label>
              <Form.Control disabled type="text" value={PayloadObj.item_name} />
            </Form.Group>

            {/* <Form.Group>
              <Form.Label>Item code</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  setPayloadObj({ ...PayloadObj, item_code: e.target.value })
                }
              />
            </Form.Group> */}

            <Form.Group>
              <Form.Label>Outer size and Umo</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={PayloadObj?.uom && (PayloadObj?.outer_size + " " + PayloadObj?.uom)}
              />
            </Form.Group>

            {/* <Form.Group>
              <Form.Label>Uom</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={PayloadObj.uom}
             
              />
            </Form.Group> */}

            <Form.Group>
              <Form.Label>Base price</Form.Label>
              <Form.Control
                disabled
                type="number"
                value={PayloadObj.base_price}
                
                // onChange={(e) =>
                //   setPayloadObj({
                //     ...PayloadObj,
                //     base_price: e.target.value,
                //     total_cost:
                //       e.target.value * PayloadObj?.sample_total_weight,
                //   })
                // }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Sample Weight (KG)</Form.Label>
              <Form.Control
                type="number"
                value={PayloadObj.sample_total_weight}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setPayloadObj({
                    ...PayloadObj,
                    sample_total_weight: e.target.value,
                    sample_unit: (e.target.value / PayloadObj?.outer_size).toFixed(2),
                    total_cost:  (e.target.value * PayloadObj?.base_price),
                  });
                }}
              />
            </Form.Group>



            {/* Sample Unit -- Sample Weight / Outer Size.  */}
            <Form.Group>
              <Form.Label>
                {" "}
                Sample Unit {"(Sample total Weight / Outer Size )"}{" "}
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={PayloadObj.sample_unit}
              />
            </Form.Group>

            {/* Total Cost --> Price * Sample Weight */}
            <Form.Group>
              <Form.Label>Total Cost {"(Price x Sample Weight)"} </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={PayloadObj.total_cost}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Warehouse</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={PayloadObj?.warehouse }
              />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={createSampleEntry}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Edit Sample Entry </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label> Item code </Form.Label>
              <Select
                
                isDisabled={true}
                // options={ItemsList}
                // defaultValue={UpdatePayloadObj?.item_code || 'Selectx'}
                defaultValue={{ label: `${UpdatePayloadObj?.item_code } ${UpdatePayloadObj?.item_name}`, value: UpdatePayloadObj?.item_code }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj?.category}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label> Date </Form.Label>
              <Form.Control disabled type="text" value={moment(`${y}-${m}-${d}`).format("DD-MMM")} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Item name</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj?.item_name }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Outer size and Umo</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj?.outer_size +' '+ UpdatePayloadObj?.uom}
              />
            </Form.Group>

            {/* <Form.Group>
              <Form.Label>Uom</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj?.uom}
              
              />
            </Form.Group> */}

            <Form.Group>
              <Form.Label>Base price</Form.Label>
              <Form.Control
                disabled
                type="number"
                value={UpdatePayloadObj.base_price}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Sample Weight (KG)</Form.Label>
              <Form.Control
                type="number"
                value={UpdatePayloadObj.sample_total_weight}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setUpdatePayloadObj({
                    ...UpdatePayloadObj,
                    sample_total_weight: e.target.value,
                    sample_unit: (e.target.value / UpdatePayloadObj?.outer_size ).toFixed(2),
                    total_cost: (e.target.value * UpdatePayloadObj?.base_price  )
                  });
                }}
              />
            </Form.Group>

 

            {/* Sample Unit -- Sample Weight / Outer Size.  */}
            <Form.Group>
              <Form.Label>
                {" "}
                Sample Unit {"(Sample total Weight / Outer Size )"}{" "}
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj.sample_unit}
              />
            </Form.Group>

            {/* Total Cost --> Price * Sample Weight */}
            <Form.Group>
              <Form.Label>Total Cost {"(Price x Sample Weight)"} </Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj.total_cost}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Warehouse</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={UpdatePayloadObj?.warehouse}
              />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={updateSampleEntry}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default SampleEntry;