import React,{useState,useEffect,useRef} from 'react'

import {
  Button as RBButton,
  Modal as RBModal,
  Row as RBRow,
  Col as RBCol,
  Container as RBContainer,
} from "react-bootstrap";


import { toast } from "react-toastify";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Modal,
    Box,
    IconButton,
    Paper,
    Typography,
    Divider,
    Grid,
    Select,
    ListSubheader,
    MenuItem,
    InputLabel

  } from '@mui/material';
import axios from "axios";
// For QR code
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";

import {LOCATIONOPTIONS_INVENTORY}  from "../../constants/allConstants.js"

import {
    DELIVERY_BASE_URL,
    deliveryCommercialServer,
  } from "../../service/servicesConfig";
import database from "../../service/firebaseConfig";
import NewListPurchaseAddAndEdit from "./NewListPurchaseAddAndEdit";
import NewPurchaseViewEdit from "./NewPurchaseViewEdit";


import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { getUserType, validateQualityyUser,validateIsAdminUser, validateStockUser, validatePurchaseUser, validatePurchaseOnlyUser, validateGRNUser } from "../../service/credentials";
const ifGrnClosedThenButtonAccess = database.database().ref(`grnClosedIsButtonDisabled`);
const ifGrnNotClosedThenButtonAccess = database.database().ref(`grnNotClosedIsButtonDisabled`);


toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);


// Adjust Modals width and height
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height:'100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
};

function NewListPurchase({apiData,grnEntryClose,itemCode,selectedDate,selectedMonth,selectedYear}) {

  let userType = sessionStorage.getItem("Logged User Type");
  // By default all any user will not have the button access if not found in DB or getting true value from DB for that specific button
 
  const [shouldDisable_add, setShouldDisable_add_grn] = useState(true);
  const [shouldDisable_edit, setShouldDisable_edit_grn] = useState(true);
  const [shouldDisable_delete, setShouldDisable_delete] = useState(true);
  const [shouldDisable_print_qr, setShouldDisable_print_qr] = useState(true);
  const [shouldDisable_view_purchase, setShouldDisable_view_purchase] = useState(true);
  const [shouldDisable_edit_purchase, setShouldDisable_edit_purchase] = useState(true);
  const [shouldDisable_view, setShouldDisable_view] = useState(true);

  const [isApprovalButtonDisabled, setIsApprovalButtonDisabled] = useState(true)

  const [locationPayload, setLocationPayload] = useState({});
  const [locationHash, setLocationLocationHash] = useState({});

  const [allApprovedItemCountForInventory, setAllApprovedItemCountForInventory] = useState(0);

  const userCity = sessionStorage.getItem("Logged User City") || "";
  

  // When the GRN entry is closed then the button access rules
//   useEffect(()=>{


//     if(grnEntryClose)
//     {
      
//     const getClosedGrnAccessRights = () => {
//       return new Promise((resolve, reject) => {
//         ifGrnClosedThenButtonAccess.once("value", (snapShot) => {
//           resolve(snapShot.val());
//         }, (error) => {
//           reject(error);
//         });
//       });
//     };
    
//     // Using the Promise
//     const fetchClosedGrnAccessRights = async () => {
//       try {
//         const getAllUserTypes = await getClosedGrnAccessRights();
        
//         if (userType in getAllUserTypes) {
//           const userBasedAccessValues = getAllUserTypes[userType];
          
//           setShouldDisable_add_grn(userBasedAccessValues.add_grn ?? true);
//           setShouldDisable_edit_grn(userBasedAccessValues.edit_grn ?? true);
//           setShouldDisable_delete(userBasedAccessValues.delete ?? true);
//           setShouldDisable_print_qr(userBasedAccessValues.print_qr ?? true);
//           setShouldDisable_view_purchase(userBasedAccessValues.view_purchase ?? true);
//           setShouldDisable_edit_purchase(userBasedAccessValues.edit_purchase ?? true);
//           setShouldDisable_view(userBasedAccessValues.view ?? true);
//         }
        
//         // console.log("values", getAllUserTypes);
//       } catch (error) {
//         console.error("Error fetching closed GRN access rights:", error);
//       }
//     };

//     fetchClosedGrnAccessRights();
//   }
//     else{
//       // When the GRN entry is not closed then the button access rules
//       const getUserAccessRights = () => {
//         return new Promise((resolve, reject) => {
//           ifGrnNotClosedThenButtonAccess.once("value", (snapShot) => {
//             resolve(snapShot.val());
//           }, (error) => {
//             reject(error);
//           });
//         });
//       };
      
//       // Using the Promise
//       getUserAccessRights()
//         .then((getAllUserTypes) => {
//           if (userType in getAllUserTypes) {
//             const userBasedAccessValues = getAllUserTypes[userType];
            
//             setShouldDisable_add_grn(userBasedAccessValues.add_grn ?? true);
//             setShouldDisable_edit_grn(userBasedAccessValues.edit_grn ?? true);
//             setShouldDisable_delete(userBasedAccessValues.delete ?? true);
//             setShouldDisable_print_qr(userBasedAccessValues.print_qr ?? true);
//             setShouldDisable_view_purchase(userBasedAccessValues.view_purchase ?? true);
//             setShouldDisable_edit_purchase(userBasedAccessValues.edit_purchase ?? true);
//             setShouldDisable_view(userBasedAccessValues.view ?? true);
//             debugger
//           }
//           // debugger;
//           // console.log("values", getAllUserTypes);
//         })
//         .catch((error) => {
//           console.error("Error fetching user access rights:", error);
//         });
          
          
//     }
//   },[grnEntryClose])

// useEffect(()=>{


//   const currentDate_disablingEditGRNForNonAdmin = () => {
//     // yyyy-mm-dd
//     const today = new Date().toISOString().split('T')[0];

//     // Parse the dates to compare them correctly
//     const parseDate = (dateString) => {
//       const [day, month, year] = dateString.split('-');
//       return `${year}-${month}-${day}`
//     }
//     const selectedDateObj = parseDate(selectedDate);
  
//     if(new Date(today) > new Date(selectedDateObj) && userType === "admin") {
//       setShouldDisable_edit_grn(true);
//       debugger
//     }

//     // console.log('Today:', today);
//     }

 
//   if(selectedDate) {
//   currentDate_disablingEditGRNForNonAdmin();
//   }

// },[selectedDate])
  
useEffect(() => {

  
      const fetchAccessRights = async () => {
        try {
          let getAllUserTypes;

          if (grnEntryClose) {
            getAllUserTypes = await new Promise((resolve, reject) => {
              ifGrnClosedThenButtonAccess.once("value", (snapShot) => {
                resolve(snapShot.val());
              }, (error) => {
                reject(error);
              });
            });
          } else {
            getAllUserTypes = await new Promise((resolve, reject) => {
              ifGrnNotClosedThenButtonAccess.once("value", (snapShot) => {
                resolve(snapShot.val());
              }, (error) => {
                reject(error);
              });
            });
          }

    
      if (userType in getAllUserTypes) {
        const userBasedAccessValues = getAllUserTypes[userType];
        
        setShouldDisable_add_grn(userBasedAccessValues.add_grn ?? true);
        setShouldDisable_edit_grn(userBasedAccessValues.edit_grn ?? true);
        setShouldDisable_delete(userBasedAccessValues.delete ?? true);
        setShouldDisable_print_qr(userBasedAccessValues.print_qr ?? true);
        setShouldDisable_view_purchase(userBasedAccessValues.view_purchase ?? true);
        setShouldDisable_edit_purchase(userBasedAccessValues.edit_purchase ?? true);
        setShouldDisable_view(userBasedAccessValues.view ?? true);
      }

      // After setting the initial state, check for the date condition for edit_grn button activation according to date
      // button deactivated for previous dates
     
        const today = new Date().toISOString().split('T')[0];
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split('-');
          return `${year}-${month}-${day}`;
        };
        const selectedDateObj = parseDate(selectedDate);

        if (new Date(today) > new Date(selectedDateObj) && validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))===false) {
          setShouldDisable_edit_grn(true);
        }
      
      } catch (error) {
        console.error("Error fetching access rights:", error);
      }
    };



  if (selectedDate) {
  fetchAccessRights();
  }

}, [grnEntryClose, userType, selectedDate]);




console.log("Bande ka data -->",apiData)


const [viewModalOpen, setViewModalOpen] = useState(false);
const [editModalOpen, setEditModalOpen] = useState(false);
const [purchaseViewModalOpen, setPurchaseViewEditModalOpen] = useState(false);


const [selectedPO_Item, setSelectedPO_Item] = useState([]); // stores list of objects for that PO Item
const [getDate, setDate] = useState(new Date());
const[APIdataFormated,setDataFromAPI] = useState({});
// For QR code
const [ProductItem, setProductItem] = useState({});
const [BarCodeList, setBarCodeList] = useState([]);
const [BarCodeSplitList, setBarCodeSplitList] = useState([]);
const [show, setShow] = useState(false);
let componentRef = useRef();
let printRef = useRef();

useEffect(() => {
  let count = 0;
  if(selectedPO_Item)
  {
  selectedPO_Item.forEach(item => {
    if ((validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && item.quality_approved === 1)) {
      count++;
    }
  });
  setAllApprovedItemCountForInventory(count);
}
}, [selectedPO_Item, LOGGED_USER_EMAIL]);

const deletePurchaseItem = (item) => {
    const itemToDelete = item.unique_key;
    console.log(item);
    const answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      const unique_key = itemToDelete;
      const firebaseKey = unique_key.substring(11);
      const total_weight_received = item.total_weight_received;

      if (total_weight_received) {
        const item_id = item.article_code;
        const grn_date = item.grn_date;

        const update_si_url = `/api/superzop/systeminventory/updatesysteminventorybasedonparameters?inventory_date=${grn_date}&item_id=${item_id}`;

        const obj = {
          grn_total_weight: `-${total_weight_received}`,
        };

        deliveryCommercialServer
          .put(update_si_url, obj)
          .then((response) => {
            const url = `/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;
            console.log("unique_key: ", unique_key, " url: ", url);

            const sqlProm = new Promise((resolve, reject) => {
              //baseUrl.delete(url).then(res => {
              deliveryCommercialServer
                .delete(url)
                .then((res) => {
                  console.log("deletePurchaseItem ", res);
                  resolve("Success");
                })
                .catch((error) => {
                  console.error(error);
                  reject(error);
                });
            });

            const firebaseProm = new Promise((resolve, reject) => {
              PurchaseDetailsData.child(
                `${getDate.getFullYear()}/${
                  getDate.getMonth() + 1 < 10
                    ? "0" + (getDate.getMonth() + 1)
                    : getDate.getMonth() + 1
                }/${
                  getDate.getDate() < 10
                    ? "0" + getDate.getDate()
                    : getDate.getDate()
                }-${
                  getDate.getMonth() + 1 < 10
                    ? "0" + (getDate.getMonth() + 1)
                    : getDate.getMonth() + 1
                }-${getDate.getFullYear()}/${firebaseKey}`
              )
                .remove()
                .then(() => {
                  resolve("Success");
                })
                .catch((error) => {
                  reject(error);
                });
            });

            Promise.all([sqlProm, firebaseProm])
              .then(() => {
                window.location.reload();

                toast(`Deleted ${itemToDelete} successfully`, {
                  type: toast.TYPE.SUCCESS,
                });
              })
              .catch((error) => {
                toast(`Failed to deleted item`, { type: toast.TYPE.ERROR });
              });

            toast("Synced System Inventory", { type: toast.TYPE.SUCCESS });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const url = `/api/superzop/commercial/purchasedetails/deletepurchasedetails?key=${unique_key}`;
        console.log("unique_key: ", unique_key, " url: ", url);


        const sqlProm = new Promise((resolve, reject) => {
          //baseUrl.delete(url).then(res => {
          deliveryCommercialServer
            .delete(url)
            .then((res) => {
              console.log("deletePurchaseItem ", res);
              resolve("Success");
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        });

        const firebaseProm = new Promise((resolve, reject) => {
          PurchaseDetailsData.child(
            `${getDate.getFullYear()}/${
              getDate.getMonth() + 1 < 10
                ? "0" + (getDate.getMonth() + 1)
                : getDate.getMonth() + 1
            }/${
              getDate.getDate() < 10
                ? "0" + getDate.getDate()
                : getDate.getDate()
            }-${
              getDate.getMonth() + 1 < 10
                ? "0" + (getDate.getMonth() + 1)
                : getDate.getMonth() + 1
            }-${getDate.getFullYear()}/${firebaseKey}`
          )
            .remove()
            .then(() => {
              resolve("Success");
            })
            .catch((error) => {
              reject(error);
            });
        });

        Promise.all([sqlProm, firebaseProm])
          .then(() => {
            window.location.reload();

            toast(`Deleted ${itemToDelete} successfully`, {
              type: toast.TYPE.SUCCESS,
            });
          })
          .catch((error) => {
            toast(`Failed to deleted item`, { type: toast.TYPE.ERROR });
          });
      }
    }
  };

  const handleShow = async (item) => {
    if (!item.grn_no)
      return toast("Error : Unable to fetch qr codes, GRN No not found.", {
        type: toast.TYPE.ERROR,
      });
    const url = `${DELIVERY_BASE_URL}/api/bag-qr?grn_number=${item.grn_no}&item_id=${item.article_code}`;

    let toast_id = toast("Fetching data, Please wait", {
      type: toast.TYPE.SUCCESS,
      autoClose: false,
    });

    let res = await axios
      .get(url)
      .catch((err) => {
        console.log("Error occured while fetching qr ids", err.message);
        return toast(`Error occured while fetching qr ids, ${err.message}`, {
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        toast.dismiss(toast_id);
      });

    const { data } = res.data;
    let qr_list = [];
    let qr_list_split = [];

    data.forEach((item, index) => {
      const qrcode = item.qr_id;
      qr_list_split.push(qrcode);
      qr_list.push(qrcode);
    });

    // formatting item_name
    // console.log("check item", item);
    let sp = item?.item_name.split("-");
    let new_item_name = sp[0];
    let obj = item;
    obj["new_item_name"] = new_item_name;
    setProductItem(obj);
    setBarCodeList(qr_list);
    setBarCodeSplitList(qr_list_split);
    setShow(true);
  };

  const handleClose = () => setShow(false);

 // Grouping By PO Number making hash Map where PO number is the key
 const formatApidata = (apiData) =>
 {
    const poNumberSort = {};
    for (let x of apiData) {
        const objectsPOnumber = x.po_number;
        if (!(objectsPOnumber in poNumberSort)) {
            poNumberSort[objectsPOnumber] = [x];
        } else {
            poNumberSort[objectsPOnumber].push(x);
        }
    }
    
    return poNumberSort
 }

    useEffect(() => {
        if (apiData) {
            const fomattedApiData = formatApidata(apiData);
            setDataFromAPI(fomattedApiData)
        }
    }, [apiData]);

  console.log(JSON.stringify(APIdataFormated))

 const shouldGiveQualityButtonAccess =(selectedPO_item) => {
    // check if the grn_no is present even for 1 item then Approve/Disable button deactivate
    for(let item of selectedPO_item)
    {
      if(item.grn_no)
      {
        return true // button will be disabled
      }
    
  }
  return false
  }


  const qualityCheckFilter = (selectedPO_item) =>{
      const qualityApprovedItems = []
      for(let items of selectedPO_item)
      {
        if(items.quality_approved)
        {
        qualityApprovedItems.push(items)
        }
      }
      return qualityApprovedItems
    }

  const ifAnyItemPending =(selectedPO_item)=>{
     
      for(let items of selectedPO_item)
      {
        if((items.quality_approved ?? "Pending")==="Pending")
        {
          return true
        }
      }
      return false // no item is pending
    }

  

    const handleViewOpen = (selectedPO_item) => {
        const shouldDisable = shouldGiveQualityButtonAccess(selectedPO_item)
        setIsApprovalButtonDisabled(shouldDisable)
        setSelectedPO_Item(selectedPO_item); // list of objects from apiData in View Modal
        setViewModalOpen(true);
        setLocationPayload({})
        setLocationLocationHash({})
    };

    const handleEditOpen = (selectedPO_item) => {
        const qualityApproved_poItems= qualityCheckFilter(selectedPO_item)
        const qualityCheckPendingStatusFilter=ifAnyItemPending(selectedPO_item)
        
        if(qualityApproved_poItems.length<=0)
        {
          return window.alert("No items to view : please ensure atleast 1 item's quality is approved")
        }

        if(qualityCheckPendingStatusFilter)
        {
          return window.alert("Please ensure all the items are either in Approved or Reject state")
        }



        setSelectedPO_Item(qualityApproved_poItems); // list objects from apiData in Edit Modal
        setEditModalOpen(true);
    };
//
    const handlPurchaseOpen = (selectedPO_item) => {
      setSelectedPO_Item(selectedPO_item); // list objects from apiData in Edit Modal
      setPurchaseViewEditModalOpen(true);
    };

    const handleCloseForViewOrEdit = () => {
        setViewModalOpen(false); 
        setEditModalOpen(false);
        setPurchaseViewEditModalOpen(false);
        setSelectedPO_Item(null); 

    };

    const handleQualityApproval=(input,poNumber,item_id)=>
    {

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "req_from": "quality_approval",
        "quality_approved": input,
        "quality_approved_user": userType,
        "po_number": poNumber,
        "article_code": item_id
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${DELIVERY_BASE_URL}/api/superzop/commercial/purchasedetails/updateApprovalDetails`, requestOptions)
      .then((response) => {
        console.log('Response:', response);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Check the content type to determine how to parse the body
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text();
        }
      }).then((result) => {
        
        // Check if response has a message property
        if (result && result.message) {
          toast(result.message, {
            type: toast.TYPE.SUCCESS,
          });
        } else {
          // If there's no message property, stringify the whole response
          toast(result, {
            type: toast.TYPE.SUCCESS,
          });
        }

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      })
      .catch((error) => {
        console.error(error);
        
        // Check if error has a message property
        const errorMessage = error.message || JSON.stringify(error);
        
        toast(errorMessage, {
          type: toast.TYPE.ERROR,
        });
      });
    }


    // if any of the entry is in pending state
    // const shouldGiveInventoryButtonAccess =(selectedPO_item) => {
      
    //   for(let item of selectedPO_item)
    //   {
    //     // if item's quality is approved/rejected but not null and and inventory is also approved for any of the item
    //     if(!item.inventory_approved && item.quality_approved)
    //     {
    //       return true 
    //     }
      
    // }


    // return false // button will be disabled
    // }


    // const shouldGiveInventoryButtonAccessCheck_2 =(selectedPO_item) => {
      
    //   for(let item of selectedPO_item)
    //   {
    //     if(item.quality_approved === null) // button needs to be disabled because one of the item is in pendind state
    //     { 
    //       return false
    //     }
    // }


    // return true // button will be enabled
    // }





  const handleLocationChange = (event,index,po_number,article_code) => {

    const makeLocationPayload = {"req_from":"inventory_approval",
    "locationList":[],
    "po_number":po_number}

    const locationIndexWiseValue = locationHash

    locationIndexWiseValue[index] = {
      "inventory_approved":1,
      "inventory_approved_user":userType,
      "article_code":article_code,
      "location":event.target.value
    }

    const entries = Object.entries(locationIndexWiseValue);
    for(let [key,value] of entries)
      {
      makeLocationPayload.locationList.push(value)
      };

      setLocationLocationHash({...locationHash,...locationIndexWiseValue})
      setLocationPayload(makeLocationPayload)  

    }


    const handleUpdateInventory =()=>{

      // const inventoryUpdationCheck_1 = shouldGiveInventoryButtonAccess(selectedPO_Item)
      // const inventoryUpdationCheck_2 = shouldGiveInventoryButtonAccessCheck_2(selectedPO_Item)

      // if(inventoryUpdationCheck_1 && inventoryUpdationCheck_2)
      // {
      // if(allApprovedItemCountForInventory==Object.keys(locationHash).length)
      // {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(locationPayload);

      if(Object.keys(locationPayload).length>0)
      {

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

    fetch(`${DELIVERY_BASE_URL}/api/superzop/commercial/purchasedetails/updateApprovalDetails`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result)

        toast(`Inventory Successfully Updated`, {
          type: toast.TYPE.SUCCESS,
        });
        
        setTimeout(()=>{
          window.location.reload();
        },2000)

       })
      .catch((error) => {
        toast(`Error In Inventory Updation`, {
          type: toast.TYPE.ERROR,
        })
        console.error(error)
      });

    }
    else
    {
      toast(`There is nothing to update`, {
        type: toast.TYPE.ERROR,
      })
    }

      // }
      // else{
      //   window.alert("Please fill all the entries of location then try again")
      // }
   // }
    // else{
    //   window.alert("Inventory can be only updated once or the Approval is of 1 or more item is in pending State")
    // }
    
   }


  

  return (
    <div>
     <TableContainer>
     <Table sx={{
                        '& .MuiTableCell-root': {
                          padding: '4px 8px',
                          fontSize: '0.75rem',
                        },
                        '& .MuiTableCell-head': {
                          fontWeight: 'bold',
                          backgroundColor: '#28a745',
                        },
                        '& .MuiButton-root': {
                          padding: '2px 8px',
                          fontSize: '0.7rem',
                          minWidth: '60px',
                          height: '24px'
                        }
                      }}  >
        <TableHead>
            <TableRow>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>PO Number</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Supplier ID</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Supplier Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>GRN Number</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Material Receiving Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Invoice No.</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Warehouse</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>View Purchase</TableCell>
            {/* <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Supplier Type</TableCell> */}
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Edit Purchase</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Print QR / Delete GRN</TableCell>
            <TableCell sx={{ fontWeight: 'bold' ,backgroundColor: '#28a745', color:"#ffffff"}}>Add / Edit GRN</TableCell>
            </TableRow>
        </TableHead>

        <TableBody>
        {Object.entries(APIdataFormated).map(([poNumber, selectedPO_item]) => {

            // using the first item to display the common values because this is generic value
            // same for all the objects present inside the array
            const firstItem = selectedPO_item[0];

            // logic changed if any item has GRN number then the GRN of that PO is done
            let isGRN_done = false
            for(let item of selectedPO_item)
            { 
              //if any of the item has GRN number then the GRN for that item is done
              if(item.grn_no)
              {
                isGRN_done = true
                break;
              }
            }


            if (userCity && firstItem.warehouse !== userCity) {
              return null; // Skip this row
            }
            
            return (
            <TableRow key={`key -- ${poNumber}`}>
            <TableCell>{firstItem.purchased_date}</TableCell> 
            <TableCell>{firstItem.po_number}</TableCell>
            <TableCell>{firstItem.supplier_id || 'N/A'}</TableCell>
            <TableCell>{firstItem.supplier_name || 'N/A'}</TableCell>
            <TableCell>{firstItem.grn_no || 'N/A'}</TableCell>
            <TableCell>{firstItem.grn_date || 'N/A'}</TableCell>
            <TableCell>{firstItem.invoice_number || 'N/A'}</TableCell>
            <TableCell>{firstItem.warehouse}</TableCell>
            {/* <TableCell>{firstItem.source_type}</TableCell> */}

            
            <TableCell>
              <Button disabled={shouldDisable_view_purchase} variant="contained" color="secondary" onClick={() => handlPurchaseOpen(selectedPO_item)}>
              View PO
              </Button>
            </TableCell>

            <TableCell>
              <Button disabled={firstItem.grn_no ? true : shouldDisable_edit_purchase} variant={isGRN_done ? "contained" : "outlined"} color="secondary" onClick={() => handlPurchaseOpen(selectedPO_item)}>
              {/* {isGRN_done ?
                <>View PO</>  : <>Edit PO</>
             
              } */}

              Edit PO
              </Button>
            </TableCell>

            <TableCell>
              <Button disabled={shouldDisable_view} sx={{ display: 'block', margin: '0 auto' }} variant="contained" color="primary" onClick={() => handleViewOpen(selectedPO_item)}>
                View
              </Button>
            </TableCell>

            <TableCell>
              {/* Disable the button if the user is not admin,purchase or GRN user */}
              <Button disabled={firstItem.grn_no ? shouldDisable_edit : shouldDisable_add} variant={isGRN_done ? "contained" : "outlined"} color="secondary"  onClick={() => handleEditOpen(selectedPO_item)}
              
              >

              {isGRN_done ?
                <>Edit GRN</>  : <>Add GRN</>
              }
              
              </Button>
            </TableCell>
        
          </TableRow>
            )
            
        })}
        </TableBody>

    </Table>
     </TableContainer>

    {/* View Modal */}
    <Modal
        open={viewModalOpen}
        onClose={handleCloseForViewOrEdit}
        aria-labelledby="view-modal-title"
        aria-describedby="view-modal-description"
        >
        <Box sx={{
            ...modalStyle,
            position: 'relative',  // Add this to position the close button
        }}>

            <Button
            aria-label="close"
            onClick={handleCloseForViewOrEdit}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            Close
            </Button>

            <Typography sx={{ textAlign: 'center',
                                 marginBottom: 2,  // Add some bottom margin if needed
                                fontWeight: 'bold'}} 
            // id="view-modal-title" 
            variant="h6" 
            component="h2"
            >
            View PO
            </Typography>
            <Box sx={{ maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
  <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
    <Table stickyHeader sx={{
                        '& .MuiTableCell-root': {
                          padding: '4px 8px',
                          fontSize: '0.75rem',
                        },
                        '& .MuiTableCell-head': {
                          fontWeight: 'bold',
                          backgroundColor: '#28a745',
                        },
                        '& .MuiButton-root': {
                          padding: '2px 8px',
                          fontSize: '0.7rem',
                          minWidth: '60px',
                          height: '24px'
                        }
                      }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Sr. No. / PO Number</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Purchase Date</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Article Code</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Item Name</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Category</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Outer Size</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Outer Type</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Bags Purchased</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Bags Received</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Base Price</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Purchase Price</TableCell>
          { (validateQualityyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))) &&
            <>          
          <TableCell  sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Approve</TableCell>
          <TableCell  sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Reject</TableCell>
          </>
          }
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Approval Status</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Approved By</TableCell>
         
          {/* validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) &&  */}
         {<TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Select Inventory Location</TableCell> }
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Selected Inventory Location</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Inventory Approved By</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Inventory Approved At</TableCell>

          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Total Weight</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Show/Print QR</TableCell>
          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Delete</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedPO_Item && selectedPO_Item.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{index+1}/{item.po_number}</TableCell>
            <TableCell>{item.purchased_date}</TableCell>
            <TableCell>{item.article_code}</TableCell>
            <TableCell>{item.item_name}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>{item.outer_size}</TableCell>
            <TableCell>{item.outer_type}</TableCell>
            <TableCell>{item.bag_purchased}</TableCell>
            <TableCell>{item.bags_received}</TableCell>
            <TableCell>{item.base_price}</TableCell>
            <TableCell>{item.purchase_price}</TableCell>

          { (validateQualityyUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) ) &&
            <>
            <TableCell><Button disabled={isApprovalButtonDisabled} variant="outlined" style={{color:isApprovalButtonDisabled ? "grey":"green"}} onClick={()=>handleQualityApproval(1,item.po_number,item.article_code)}>Approve</Button></TableCell>
            <TableCell><Button disabled={isApprovalButtonDisabled} variant="outlined" style={{color: isApprovalButtonDisabled ? "grey":"red"}} onClick={()=>handleQualityApproval(0,item.po_number,item.article_code)}>Reject</Button></TableCell>
            </> 
          }
            <TableCell>{item.quality_approved==1 ? "Approved" : (item.quality_approved ?? null)==null ? "Pending" : "Rejected"}</TableCell>
            <TableCell>{item.quality_approved_user}</TableCell>
         


            {(validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && ((item.quality_approved==1 || (item.quality_approved ?? 1)) && !item.inventory_approved)) ?
            <> 
            {/* <InputLabel id="location-select-label">Location</InputLabel> */}
            <Select
              // labelId="location-select-label"
              // id="location-select"

              value={locationHash[index]}
              label="Location"
              onChange={(event) => handleLocationChange(event,index,item.po_number,item.article_code)}
            >
              {LOCATIONOPTIONS_INVENTORY.map((group) => [
                <ListSubheader key={group.header}>{group.header}</ListSubheader>,
                ...group.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ])}
            </Select>
            </> : 
            <> 
            
            <Select
              // labelId="location-select-label"
              // id="location-select"
              disabled={true}
              value={locationHash[index]}
              label="Location"
              onChange={(event) => handleLocationChange(event,index,item.po_number,item.article_code)}

              sx={{
                '&.Mui-disabled': {
                  color: 'text.disabled',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'action.disabled',
                  },
                },
              }}
            >
              {LOCATIONOPTIONS_INVENTORY.map((group) => [
                <ListSubheader key={group.header}>{group.header}</ListSubheader>,
                ...group.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ])}
            </Select>
            </>}

            <TableCell>{item.location}</TableCell>
            <TableCell>{item.inventory_approved ? "" : "Not Approved "}{item.inventory_approved_user ? item.inventory_approved_user : ""}</TableCell>
            <TableCell>
              {item.approved_date ? (() => {
                // Create a Date object from the original timestamp
                const originalDate = new Date(item.approved_date);
                
                // Manually adjust to IST by adding 5 hours and 30 minutes
                const istDate = new Date(originalDate.getTime() + (5 * 60 + 30) * 60 * 1000);
                
                // Format to a readable string
                return istDate.toLocaleString('en-IN', {
                  timeZone: 'Asia/Kolkata',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                });
              })() : null}
            </TableCell>


            <TableCell>{item.total_weight}</TableCell>
            <TableCell>
      
                  {item.grn_no  && (<Button
                    variant="contained"
                    disabled={shouldDisable_print_qr}
                    sx={{
                      fontSize: "14px",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                    }}
                    onClick={() => handleShow(item)}
                  >
                    Show/Print QR
                  </Button>) }
            
            </TableCell>
            <TableCell>
              <Button disabled={shouldDisable_delete} variant="contained" color="error" onClick={() => deletePurchaseItem(item)}>
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

<Divider />

  <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
 {validateStockUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <Button variant="contained"  
  disabled={!isApprovalButtonDisabled}
  sx={{ 
    backgroundColor: "#28a745",
    color: "white",
    mr: 1,
    '&:hover': {
      backgroundColor: "#218838"
    },
    mr: 1
  }}
   onClick={()=>handleUpdateInventory()} >
    Update Inventory
  </Button>
}
  </Box>

        </Box>
        
        
 
    </Modal>



        {/* Edit Modal */}
        <Modal
                open={editModalOpen}
                onClose={handleCloseForViewOrEdit}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* grnEntryClose(remains false),itemCode={itemCode} is just an empty string it has no use keeping it maintain the format/structure that't it*/}
                     <NewListPurchaseAddAndEdit selectedPO_Item={selectedPO_Item} handleCloseForViewOrEdit={handleCloseForViewOrEdit} itemCode={itemCode} selectedDate={selectedDate} selectedMonth={selectedMonth} selectedYear={selectedYear} grnEntryClose={grnEntryClose} /> 
                </Box>
             
            </Modal>



      {/* Purchase Modal */}
      <Modal
          open={purchaseViewModalOpen}
          onClose={handleCloseForViewOrEdit}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
      >
          <Box sx={modalStyle}>
              {/* grnEntryClose(remains false),itemCode={itemCode} is just an empty string it has no use keeping it maintain the format/structure that't it*/}
                <NewPurchaseViewEdit selectedPO_Item={selectedPO_Item} handleCloseForViewOrEdit={handleCloseForViewOrEdit} itemCode={itemCode} selectedDate={selectedDate} selectedMonth={selectedMonth} selectedYear={selectedYear} grnEntryClose={grnEntryClose} /> 
          </Box>
        
      </Modal>

      
    

    {/* QR Modal */}
    {/* Sowing this Modal on UI because of the clashes between different UI*/}
    {/* Sowing this Modal on UI and for printing using the reference of react bootstrap */}
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="print-qr-code-modal"
      aria-describedby="modal-to-print-qr-codes"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography id="print-qr-code-modal" variant="h6" component="h2">
            Print QR Code
          </Typography>
          
          <style type="text/css" media="print">
            {`@page { size: 50mm 25mm; orientation: portrait; }`}
          </style>
          
          <ReactToPrint
            trigger={() => <Button variant="contained" color="primary" sx={{ mt: 1 }}>Print</Button>}
            //content={() => componentRef.current
            content={() => printRef 
          }
          />
        </Box>

        <Divider />

        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 1 }}>
          <Box ref={componentRef}>
            {BarCodeList && BarCodeList.map((item, i) => (
              <Paper key={i} elevation={3} sx={{ mb: 4, p: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <QRCode
                      title="stockConversion"
                      value={item}
                      size={500}
                      style={{
                        height: "auto",
                        maxWidth: "70%",
                        width: "auto",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                      {ProductItem?.new_item_name?.toUpperCase()}
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                      {BarCodeSplitList[i]}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        </Box>

        <Divider />

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <ReactToPrint
            trigger={() => <Button variant="contained" color="primary">Print</Button>}
            // content={() => componentRef.current
            content={() => printRef}
          />
        </Box>
      </Paper>
    </Modal>




    <RBModal show={show} dialogClassName="custom-modal" onHide={handleClose}>
      <RBModal.Header closeButton>
        <RBModal.Title> Print Qr Code </RBModal.Title>
        <style type="text/css" media="print">
          {"\
          @page { size: 50mm 25mm, orientation:portrait  }\
        "}
        </style>
        {/* <ReactToPrint
          trigger={() => <RBButton> Print </RBButton>}
          content={() => printRef}
        /> */}
      </RBModal.Header>

      <RBModal.Body ref={(el) => (printRef = el)}>
        <RBContainer>
          {BarCodeList &&
            BarCodeList.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{
                    pageBreakAfter: "always",
                    marginTop: "150px",
                    marginLeft: "5px",
                  }}
                >
                  <RBRow className="">
                    <RBCol>
                      <QRCode
                        title="stockConversion"
                        value={item}
                        size={500}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "auto",
                        }}
                        viewBox={`0 0 200 200`}
                      />
                    </RBCol>
                    <RBCol>
                      <h2 style={{ fontSize: "110px" }}>
                        <b>
                          <u> {ProductItem?.new_item_name?.toUpperCase()} </u>
                        </b>
                      </h2>
                    </RBCol>
                  </RBRow>

                  <RBRow>
                    <h3 style={{ fontSize: "110px" }}>
                      {BarCodeSplitList[i]}{" "}
                    </h3>
                  </RBRow>
                </div>
              );
            })}
        </RBContainer>
      </RBModal.Body>

      <RBModal.Footer>
        <RBButton variant="secondary" onClick={handleClose}>
          Close
        </RBButton>
        <style type="text/css" media="print">
          {"\
          @page { size: 50mm 25mm  }\
        "}
        </style>
        {/* <ReactToPrint
          trigger={() => <RBButton> Print </RBButton>}
          content={() => printRef}
        /> */}
      </RBModal.Footer>
    </RBModal>

    {/* QR Modal */}
    {/* <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="print-qr-code-modal"
        aria-describedby="modal-to-print-qr-codes"
        >

  

        <Paper
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        }}
        >

        <Box sx={{ p: 2 }}>

        <Button
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        >
        Close
        </Button>


            <Typography id="print-qr-code-modal" variant="h6" component="h2">
            Print QR Code
            </Typography>

            

            <style type="text/css" media="print">

            {`

                @page {
                size: 60mm 34mm;
                margin: 0;
                }

                body {
                margin: 0;
                }

                .qr-page {
                page-break-after: always;
                width: 60mm;
                height: 45mm;
                padding: 2mm;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                }

                .qr-code {
                width: 48mm !important;
                height: 38mm !important;
                }

                .qr-text {
                font-size: 10px;
                text-align: center;
                margin-top: 2mm;
                line-height: 1;
                }
            `}
            </style>
            <ReactToPrint
            trigger={() => <Button variant="contained" color="primary" sx={{ mt: 1 }}>Print</Button>}
            content={() => componentRef.current}
            />
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
            <Box ref={componentRef}>
            {BarCodeList && BarCodeList.map((item, i) => (
                <Box key={i} className="qr-page">
                <QRCode
                    title="stockConversion"
                    value={item}
                    size={500}
                    className="qr-code"
                />
                <Typography className="qr-text" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {ProductItem?.new_item_name?.toUpperCase()}
                </Typography>
                <Typography className="qr-text">
                    {BarCodeSplitList[i]}
                </Typography>
                </Box>
            ))}
            </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <ReactToPrint
            trigger={() => <Button variant="contained" color="primary">Print</Button>}
            content={() => componentRef.current}
            />
            <Button onClick={handleClose} sx={{ mr: 1 }}>
            Close
            </Button>
        </Box>
        </Paper>
    </Modal> */}

    </div>
  )
}

export default NewListPurchase
